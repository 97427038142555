const DefaultTradeInIcon = () => {
  return (
    <svg
      width="48"
      height="44"
      viewBox="0 0 48 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V40C48 42.2091 46.2091 44 44 44H4C1.79086 44 0 42.2091 0 40V4Z"
        fill="#F2F3F3"
      />
      <g clip-path="url(#clip0_9920_302)">
        <path d="M40 6H8V38H40V6Z" fill="#F2F3F3" />
        <path
          d="M28.5747 8.66666C28.6265 8.66666 28.6782 8.66666 28.7329 8.66666C28.8598 10.2694 28.2613 11.4669 27.5339 12.3341C26.8202 13.1953 25.8428 14.0306 24.2621 13.9039C24.1566 12.3241 24.7561 11.2154 25.4826 10.3502C26.1563 9.54385 27.3914 8.82633 28.5747 8.66666Z"
          fill="#B3B3B3"
        />
        <path
          d="M34 26.3578C34 26.3745 34 26.3891 34 26.4047C33.5221 27.84 32.8405 29.0701 32.0086 30.2117C31.2492 31.248 30.3187 32.6427 28.6571 32.6427C27.2213 32.6427 26.2676 31.7272 24.7962 31.7022C23.2396 31.6772 22.3836 32.4677 20.9604 32.6667C20.7976 32.6667 20.6348 32.6667 20.4752 32.6667C19.4301 32.5167 18.5867 31.6959 17.9723 30.9564C16.1605 28.7712 14.7605 25.9485 14.5 22.3363C14.5 21.9822 14.5 21.6291 14.5 21.2749C14.6103 18.6897 15.877 16.5878 17.5606 15.5692C18.4492 15.0276 19.6707 14.5661 21.0308 14.7724C21.6137 14.862 22.2093 15.0599 22.7313 15.2557C23.226 15.4442 23.8446 15.7785 24.4307 15.7608C24.8277 15.7494 25.2226 15.5442 25.6228 15.3994C26.7949 14.9797 27.9439 14.4984 29.4585 14.7245C31.2787 14.9974 32.5705 15.7994 33.3688 17.0368C31.829 18.0086 30.6117 19.473 30.8197 21.9738C31.0045 24.2455 32.3363 25.5746 34 26.3578Z"
          fill="#B3B3B3"
        />
      </g>
      <defs>
        <clipPath id="clip0_9920_302">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(8 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DefaultTradeInIcon;
