import {
  ERRORPAGE,
  REVENUSCHARCHECO,
  OPENBANKINGCHOICE,
  SITUATIONFAMILIALE,
} from '@vat/configuration';
import { useCallback, useMemo, useState } from 'react';
import {
  IOpportunityState,
  fetchWay,
  setSteps,
  useAppDispatch,
  setVatType,
  useAppSelector,
  selectParams,
  setOpenbankingChoice,
  IStep,
  updateParcoursNavigation,
} from '@sweb-front/store';
import { useCreditParameters, useManagingStep } from '@sweb-front/hooks';
import { useNavigate } from 'react-router-dom';
import { useCloseOpportunity } from '@vat/utils';
import { useTranslation } from 'react-i18next';
import { postData } from '../utils';
import { trackEvent } from '@sweb-front/utils';

const AUTOMATICMETHOD = 'AUTO';
const STANDARDMETHOD = 'STANDARD';

const useOpenBankingChoiceForm = (
  personId: string,
  opportunity: IOpportunityState,
  steps: IStep[],
  isVATRib: boolean
) => {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [methodConsent, setMethodConsent] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [showModalLeave, setShowModalLeave] = useState<boolean>(false);

  const { recalculateCreditParameters } = useCreditParameters();
  const { goToNextStep } = useManagingStep();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateAbortErrorMessage } = useCloseOpportunity();
  const paramsArgs = useAppSelector(selectParams);
  const updateIsActionLoading = (state: boolean) => {
    setIsActionLoading(state);
  };

  const onUpdateMethodConsent = () => {
    setMethodConsent(!methodConsent);
  };

  const handleSelectedMethod = (idMethod: String) => {
    if (idMethod === STANDARDMETHOD && methodConsent) {
      setMethodConsent(false);
    }
    setSelectedMethod(idMethod);
  };

  const onSubmit = useCallback(
    async (e) => {
      if (isActionLoading) {
        return;
      }
      // cas standards method
      e.preventDefault();
      let hasError = false;
      if (isActionLoading) {
        return;
      }
      updateIsActionLoading(true);
      await postData(
        'vendors/opportunities/v1/opportunity',
        {
          persons: [
            {
              personId,
              openbankingConsentBo: methodConsent,
            },
          ],
        },
        () => {},
        () => {
          updateAbortErrorMessage(
            'useOBConsentment::updateOpportunity  an error occured when saving OB consentment'
          );
          hasError = true;
          dispatch(
            updateParcoursNavigation({
              name: OPENBANKINGCHOICE,
              loaded: true,
              actionPageDone: true,
              disabled: true,
              params: {
                hasError: true,
              },
            })
          );
          return navigate(ERRORPAGE);
        }
      );
      if (hasError) {
        return;
      }
      if (selectedMethod === STANDARDMETHOD) {
        try {
          const { appSteps, result } = await recalculateCreditParameters({
            ...paramsArgs,
            openBankingChoice: 'N',
          });
          if (appSteps && appSteps.length > 0) {
            dispatch(fetchWay(result));
            dispatch(setVatType(result?.wayType?.toLowerCase()));
            dispatch(setOpenbankingChoice('STANDARD'));
            // remove isSubStep if it is the first page and replace the situation step by informations on the stepLine
            const firstStep = appSteps.find((st) => st.step === 1);
            if (
              firstStep &&
              (firstStep.paths.includes(SITUATIONFAMILIALE) ||
                firstStep.paths.includes(REVENUSCHARCHECO))
            ) {
              firstStep.label = t('basicInformations.stepTitle');
            }
            delete firstStep.isSubStep;
            dispatch(setSteps(appSteps));
            window.history.replaceState(null, document.title, '/');
            dispatch(
              updateParcoursNavigation({
                name: OPENBANKINGCHOICE,
                loaded: true,
                actionPageDone: true,
                disabled: true,
              })
            );
            navigate('/');
          } else {
            dispatch(
              updateParcoursNavigation({
                name: OPENBANKINGCHOICE,
                loaded: true,
                actionPageDone: true,
                disabled: true,
                params: {
                  hasError: true,
                },
              })
            );
            updateAbortErrorMessage(
              'OpenBanking::fetchParameters en mode Manuel, les étapes du parcours sont vides'
            );
            navigate(ERRORPAGE);
          }
        } catch {
          updateAbortErrorMessage(
            'OpenBanking::fetchParameters en mode manuel Recalcul du paramétrage KO ou gestion des étapes KO'
          );
          dispatch(
            updateParcoursNavigation({
              name: OPENBANKINGCHOICE,
              loaded: true,
              actionPageDone: true,
              disabled: true,
              params: {
                hasError: true,
              },
            })
          );
          navigate(ERRORPAGE);
        } finally {
          updateIsActionLoading(false);
        }
      }
      // cas automatic method
      else if (selectedMethod === AUTOMATICMETHOD) {
        dispatch(
          updateParcoursNavigation({
            name: OPENBANKINGCHOICE,
            loaded: true,
            actionPageDone: true,
            disabled: true,
          })
        );
        goToNextStep();
      }
      updateIsActionLoading(false);
    },
    [
      isActionLoading,
      selectedMethod,
      fetchWay,
      setSteps,
      methodConsent,
      steps,
      dispatch,
      goToNextStep,
    ]
  );

  const isFormValid = useMemo(
    () =>
      selectedMethod === STANDARDMETHOD ||
      (selectedMethod === AUTOMATICMETHOD && methodConsent),
    [selectedMethod, methodConsent]
  );

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const onShowModalLeave = useCallback((val: boolean) => {
    setShowModalLeave(val);
  }, []);

  const onShowModalLeaveFn = () => {
    onShowModalLeave(false);
  };

  const openPopUpRetour = useCallback(() => {
    trackEvent({
      event: 'popinDisplay',
      modaleName: 'E-Commerce : Page Choix Open Banking CB : Retour',
    });
    onShowModalLeave(true);
  }, []);

  return {
    AUTOMATICMETHOD,
    STANDARDMETHOD,
    handleSelectedMethod,
    onSubmit,
    openModal,
    closeModal,
    onUpdateMethodConsent,
    showModal,
    showModalLeave,
    isActionLoading,
    selectedMethod,
    methodConsent,
    isFormValid,
    onShowModalLeaveFn,
    openPopUpRetour,
  };
};

export default useOpenBankingChoiceForm;
