import { ReactNode } from 'react';
import {
  BubbleInfoWrapper,
  BubbleInfoTextWrapper,
  BubbleInfoListWrapper,
  BubbleInfoTitleWrapper,
  SvgIconWrapper,
} from './styles';
import { InformationLampIcon } from '../Icons';

/**
 * @property {string} marker the css by default have many default values
 * to see some list, see the documentation https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type
 * otherwise, make the @property {boolean} isCustomedMarker as true to put some customed markers
 */

export interface IBubbleInfoProps {
  id?: string;
  showBubbleAfter?: boolean;
  title?: ReactNode;
  strongTitle?: ReactNode;
  bubbleItems?: ReactNode[];
  marker?: string;
  isCustomedMarker: boolean;
}

const BubbleInfo = ({
  id,
  showBubbleAfter = true,
  title,
  strongTitle,
  bubbleItems,
  isCustomedMarker = false,
  marker,
}: IBubbleInfoProps) => {
  return (
    <BubbleInfoWrapper id={id} showBubbleAfter={showBubbleAfter}>
      <SvgIconWrapper className={id}>
        <InformationLampIcon
          fill={
            id === 'bubbleinfo-income-activity' ||
            id === 'bubbleinfo-refused-page'
              ? '#007590'
              : '#0071E3'
          }
        />
      </SvgIconWrapper>
      <BubbleInfoTextWrapper>
        {(title || strongTitle) && (
          <BubbleInfoTitleWrapper className={id}>
            {title && <span className="light-title">{title}</span>}
            {strongTitle && <span className="strong-title">{strongTitle}</span>}
          </BubbleInfoTitleWrapper>
        )}
        {bubbleItems && bubbleItems.length > 0 && (
          <BubbleInfoListWrapper
            marker={marker}
            isCustomedMarker={isCustomedMarker}
          >
            {bubbleItems?.map((item, index) => (
              <li key={`bubble-item-${index + 1}`}>{item}</li>
            ))}
          </BubbleInfoListWrapper>
        )}
      </BubbleInfoTextWrapper>
    </BubbleInfoWrapper>
  );
};

export default BubbleInfo;
