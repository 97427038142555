import { selectNavigation, useAppSelector } from '@sweb-front/store';

import { ApprovedIconPage, Button } from '@vat/components';
import { Trans, useTranslation } from 'react-i18next';
import {
  ContentWrapper,
  LoanHasBeenAcceptedContainer,
  LoanHasBeenAcceptedParagraph,
  ReceptionParagraph,
  SVGIconWrapper,
  SectionWrapper,
  TitleCmp,
  CustomizedValidateButtonWrapper,
  DocumentInPersonalAccountParagraphExplanation,
  ContentDescriptionWrapper,
  MentionInformationWrapper,
  FirstGetContractCopyBold,
} from './styles';
import useApprovedPayment from './useApprovedPayment';
import { BubbleInfo } from '@sweb-front/components';
import { CHANELTYPE } from '@sweb-front/types';

const ApprovedPayment = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const { distributionChannelCD } = opportunity;
  const parameters = useAppSelector((state) => state.parameters.state);
  const navigation = useAppSelector(selectNavigation);

  const { onContractClick } = useApprovedPayment(
    opportunity,
    parameters,
    navigation
  );

  const { wayType } = parameters;

  return (
    <SectionWrapper>
      <SVGIconWrapper>
        <ApprovedIconPage />
      </SVGIconWrapper>
      <ContentWrapper>
        <TitleCmp>{t('common.congratulations')}</TitleCmp>
        <LoanHasBeenAcceptedContainer>
          <LoanHasBeenAcceptedParagraph>
            {t('responses.accepted.definitely')}
          </LoanHasBeenAcceptedParagraph>
        </LoanHasBeenAcceptedContainer>
        <ReceptionParagraph>
          <Trans
            i18nKey={`responses.accepted.VAT.receiptDocuments`}
            components={{
              approvedReceiptDocuments: (
                <strong id="approvedReceiptDocuments">
                  approvedReceiptDocuments
                </strong>
              ),
            }}
          />
        </ReceptionParagraph>
        <BubbleInfo
          id="bubble-info-approved-payment"
          title={t(`responses.accepted.VAT.downloadDocuments`)}
          marker="default"
          hasIcon={true}
          hasChevron={false}
          textColor="#007590"
        />
        <CustomizedValidateButtonWrapper
          style={
            distributionChannelCD === CHANELTYPE.POS
              ? {
                  marginTop: '3.2rem',
                }
              : {}
          }
        >
          <Button
            id="contract-download-button-approved-payment"
            level="primary"
            onClick={onContractClick}
            isBold
          >
            {t(`responses.buttons.VAT.contractArea`)}
          </Button>
        </CustomizedValidateButtonWrapper>
        <ContentDescriptionWrapper>
          <FirstGetContractCopyBold>
            {t(`responses.accepted.VAT.firstGetContractCopy`)}
          </FirstGetContractCopyBold>
          <DocumentInPersonalAccountParagraphExplanation>
            {wayType === 'VATR' && distributionChannelCD === CHANELTYPE.POS
              ? t(`responses.accepted.VATC.contractCopy`)
              : t(`responses.accepted.${wayType}.contractCopy`)}
          </DocumentInPersonalAccountParagraphExplanation>
          <MentionInformationWrapper>
            <sup>*</sup>
            <span>{t('responses.accepted.mentionInformation')}</span>
          </MentionInformationWrapper>
        </ContentDescriptionWrapper>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default ApprovedPayment;
