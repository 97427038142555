import { ReactNode } from 'react';
import {
  CustomedBubbleInfoWrapper,
  ImgWrapper,
  StyledCloseIcon,
} from './styles';

export interface ICustomedBubbleInfoProps {
  id?: string;
  description?: ReactNode;
  descriptionComp?: ReactNode;
  hasIcon?: boolean;
  hasClosebtn?: boolean;
  onClose?: () => void;
}

const CustomedBubbleInfo = ({
  id,
  description,
  descriptionComp,
  hasIcon = true,
  hasClosebtn = false,
  onClose,
}: ICustomedBubbleInfoProps) => {
  return (
    <CustomedBubbleInfoWrapper id={id}>
      {hasIcon && (
        <ImgWrapper>
          <base-icon icon="info-fill" />
        </ImgWrapper>
      )}
      <div className="bubbleinfo-description">
        {description}
        {descriptionComp}
      </div>
      {hasClosebtn && (
        <ImgWrapper onMouseDown={onClose} className="remove-btn">
          <StyledCloseIcon strokeWidth={1} />
        </ImgWrapper>
      )}
    </CustomedBubbleInfoWrapper>
  );
};

export default CustomedBubbleInfo;
