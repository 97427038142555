import { Section, Title } from '@sweb-front/components';
import { NextButtonWrapper } from '@sweb-front/styles';
import styled from 'styled-components';

export const SectionWrapper = styled((props) => <Section {...props} />)`
  padding: 0rem;
  font-family: 'Open Sans Bold';
  color: var(--neutrals-500);
  img {
    self-align: center;
  }
`;

export const SVGIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 3.2rem;
`;

export const ContentWrapper = styled.div`
  text-align: center;
`;

export const TitleCmp = styled((props) => <Title {...props} />)`
  margin: 0;
  font-size: 2.4rem;
  line-height: 3.2rem;
  text-align: center;
  font-family: 'Open Sans Bold';
  color: var(--neutrals-700);
`;

export const SeparatorWrapper = styled.div`
  margin: 0;
`;

export const Paragraph = styled.p<{ marginTop?: string }>`
  font-family: 'Open Sans';
  color: var(--neutrals-500);
  margin: 0;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  margin-top: ${({ marginTop }) => marginTop || '3.2rem'};
`;

export const ParagraphTitle = styled(Paragraph)`
  font-family: 'Ubuntu Bold';
  font-size: 20px;
  margin-top: 3.2rem;
  line-height: 28px;
`;

export const PrintAndDownloadParagraph = styled((props) => (
  <Paragraph {...props} />
))`
  margin-bottom: 1.6rem;
  #downloadDocuments {
    font-family: 'Open Sans Bold';
  }
`;

export const PrintAndDownloadParagraphBold = styled.a`
  font-family: 'Open Sans Bold';
  text-decoration: none;
`;

export const NumericListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  margin-top: 1.6rem;
`;

export const CustomizedValidateButtonWrapper = styled(NextButtonWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.6rem;
  & base-button {
    flex-grow: 1;
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-family: 'Open Sans Bold';
    --color-button: var(--neutrals-0);
    background: var(--main-green-500);
    border: 1px solid var(--main-green-500);
    max-width: 32rem;
    height: 5.6rem;
    padding: 1.25rem;
  }
  & base-icon {
    width: 0;
  }
`;

export const DocumentInPersonalAccountParagraph = styled((props) => (
  <PrintAndDownloadParagraph {...props} />
))`
  font-family: 'Open Sans Bold';
  margin-top: 3.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--neutrals-500);
`;

export const DocumentInPersonalAccountParagraphExplanation = styled(
  DocumentInPersonalAccountParagraph
)`
  font-family: 'Open Sans';
  margin-top: 1.6rem;

  &:last-of-type {
    margin-bottom: 3.2rem;
  }
`;

export const FirstGetContractCopyBold = styled.span`
  font-family: 'Open Sans Bold';
`;

export const BackButtonWrapper = styled((props) => (
  <CustomizedValidateButtonWrapper {...props} />
))`
  & base-button {
    padding: 0.8rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    height: 4.2rem;
    --color-button: var(--main-green-500);
    background: var(--neutrals-0);
    border: 1px solid var(--main-green-500);
  }
`;

export const ContentDescriptionWrapper = styled.div`
  margin-top: 3.2rem;
  #firstGetContractCopyBold {
    font-family: 'Open Sans Bold';
  }
`;

export const MentionInformationWrapper = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-top: 3.2rem;
  color: var(--neutrals-500);
  span {
    margin-left: 0.3rem;
  }
`;
