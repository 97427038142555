/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { InfoGlyphIcon, CrossCircleIcon } from '../Icons';

export const InputTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
`;

export const InputLabel = styled.span<{
  disabled?: boolean;
  isReadOnly?: boolean;
}>`
  color: ${({ theme, disabled, isReadOnly }) => {
    if (isReadOnly) {
      return theme.vendor.colors.text;
    }
    if (disabled) {
      return theme.vendor.colors.disabled;
    }
    return theme.vendor.colors.text;
  }};
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`;

export const UnstyledInput = styled.input`
  border: none;
  background-image: none;
  background-color: transparent;
  color: var(--neutrals-500);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  &:focus {
    outline: none;
  }
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0.1rem;
  width: 100%;
  height: 95%;
  padding-left: 1.6rem;
  ::placeholder {
    color: ${({ theme }) => theme.vendor.colors.placeholder};
  }
`;

export const InputContainer = styled.div<{
  disabled: boolean;
  isError: boolean;
  isValid: boolean;
  isReadOnly: boolean;
  isTouched?: boolean;
}>`
  border: 1px solid
    ${({ theme, disabled, isError, isValid, isReadOnly, isTouched }) => {
      if (isReadOnly) {
        return 'transparent';
      }
      if (disabled) {
        return theme.vendor.colors.disabled;
      }
      if (isError) {
        return theme.vendor.colors.error;
      }
      if (isValid && isTouched) {
        return theme.vendor.colors.primary;
      }
      return theme.vendor.colors.border;
    }};
  border-radius: 4px;
  height: 4.8rem;
  background-color: ${({ theme, disabled, isReadOnly }) =>
    disabled || isReadOnly
      ? theme.vendor.colors.disabledBackground
      : '#ffffff'};
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  flex-grow: 1;
  cursor: text;

  &.mobile {
    width: 22.4rem;
  }
`;

export const InfoGlyphIconWrapper = styled(InfoGlyphIcon)`
  height: 2.4rem;
`;

export const CrossCircleWrapper = styled(CrossCircleIcon)`
  height: 2.4rem;
`;

export const CrossWrapper = styled.button`
  height: 2.4rem;
  cursor: pointer;
  border: none;
  background: transparent;
`;

export const IconWrapper = styled.div`
  height: 100%;
  width: 5.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ErrorMessageWrapper = styled.span`
  color: ${({ theme }) => theme.vendor.colors.error};
`;

export const OptionalInputText = styled.span`
  color: ${({ theme }) => theme.vendor.colors.titleCard};
  font-family: 'Open Sans';
  font-size: 1.4rem;
`;
