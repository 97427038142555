import { styled } from 'styled-components';
import { DESKTOP, TAB, MOBILE } from '@sweb-front/utils';
import { CloseIcon } from '../Icons';

export const StyledCSSBackgroundTransition = styled.div`
  .modal-background-enter {
    background-color: rgba(0, 0, 0, 0);
    z-index: -1;
    display: flex;
  }
  .modal-background-enter-active {
    transition: background-color 50ms ease-in-out;
    background-color: rgba(0, 0, 0, 0.15);
  }
  .modal-background-enter-done {
    z-index: 49;
    background-color: rgba(0, 0, 0, 0.15);
    display: flex;
  }
  .modal-background-exit {
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 49;
  }
  .modal-background-exit-active {
    transition: background-color 50ms ease-in-out;
    background-color: rgba(0, 0, 0, 0);
  }
  .modal-background-exit-done {
    z-index: -1;
    background-color: rgba(0, 0, 0, 0);
    display: none;
  }
`;

export const ModalBackground = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  &:not(.recomputed-rate-modal):not(#recap-modal-tradeIn) {
    padding: 10rem;
  }
  &.recomputed-rate-modal {
    @media screen and ${MOBILE} {
      padding: 1.6rem;
    }
    @media screen and ${DESKTOP} {
      padding: 2.4rem;
    }
  }
  display: none;
  flex-direction: row;
  justify-content: center;
`;

export const StyledCSSModalTransition = styled.div`
  .modal-enter {
    display: block;
    &:not(.recomputed-rate-modal) {
      @media screen and ${MOBILE} {
        transform: translateY(100vh);
        z-index: 50;
      }
    }
    &.recomputed-rate-modal {
      @media screen and ${MOBILE} {
        transform: translateY(0vh);
        z-index: 50;
      }
    }
    @media screen and ${DESKTOP} and ${TAB} {
      opacity: 0;
    }
  }

  .modal-enter-active {
    &:not(.recomputed-rate-modal) {
      @media screen and ${MOBILE} {
        transition: transform 250ms ease-in-out;
        transform: translateY(1vh);
      }
    }
    &.recomputed-rate-modal {
      @media screen and ${MOBILE} {
        transition: transform 300ms ease-in-out;
        transform: translateY(0vh);
      }
    }
    @media screen and ${DESKTOP} and ${TAB} {
      transition: opacity 150ms ease-in-out;
      opacity: 100;
    }
  }

  .modal-enter-done {
    z-index: 50;
  }

  .modal-exit {
    &:not(.recomputed-rate-modal) {
      @media screen and ${MOBILE} {
        transform: translateY(1vh);
      }
    }
    &.recomputed-rate-modal {
      @media screen and ${MOBILE} {
        transform: translateY(0vh);
      }
    }
    z-index: 50;
    @media screen and ${DESKTOP} and ${TAB} {
      opacity: 100;
    }
  }

  .modal-exit-active {
    &:not(.recomputed-rate-modal) {
      @media screen and ${MOBILE} {
        transition: transform 250ms ease-in-out;
        transform: translateY(100vh);
      }
    }
    &.recomputed-rate-modal {
      @media screen and ${MOBILE} {
        transition: transform 300ms ease-in-out;
        transform: translateY(0vh);
      }
    }
    @media screen and ${DESKTOP} and ${TAB} {
      transition: opacity 150ms ease-in-out;
      opacity: 0;
    }
  }

  .modal-exit-done {
    display: none;
    z-index: -1;
  }
`;

export const ModalWrapper = styled.div<{ id?: string }>`
  position: fixed;
  z-index: -1;
  &:not(.recomputed-rate-modal):not(#recap-modal-tradeIn) {
    bottom: 0;
  }
  left: 0;
  width: 100vw;
  border-radius: 0.8rem;
  &#recap-modal-tradeIn {
    border-radius: 1.6rem;
    @media screen and (max-width: 768px) {
      height: 100%;
      border-bottom-left-radius: 0rem;
      border-bottom-right-radius: 0rem;
    }
  }
  max-height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  &:not(#recap-modal-tradeIn) {
    padding-bottom: 1.6rem;
  }
  &:not(.recomputed-rate-modal) {
    @media screen and ${TAB} {
      width: 56%;
      overflow: hidden;
      z-index: 50;
      &:not(#recap-modal-tradeIn) {
        bottom: auto;
        max-height: 80vh;
        margin: 5rem 25vw;
      }
    }
  }
  &.recomputed-rate-modal {
    @media screen and ${TAB} {
      width: 56%;
      z-index: 50;
      margin: 0 25vw;
    }
  }
  &.income-expenses-form,
  &.professional-form {
    height: 100vh;
  }
  &.recomputed-rate-modal {
    background-color: #e5f5fd;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.vendor.colors.border};
  width: 2.2rem;
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  flex-direction column;
  justify-content: end;
  padding: 1.6rem;
  &.information-modal {
    padding: 1.6rem;
  }
`;

export const StyledCloseButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.05),
    1px 4px 8px -2px rgba(0, 0, 0, 0.1);
  border: 0.1rem solid ${({ theme }) => theme.vendor.colors.light};
  border-radius: 100%;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  padding: 0.4rem;
  justify-content: center;
`;
