import { Centering, RedirectionComponent, Theme } from '@sweb-front/components';
import { getTheme, GlobalStyles } from '@sweb-front/styles';
import { CHANELTYPE, TParamWaitingPage } from '@sweb-front/types';
import { ErrorBoundary, Footer, SpecificErrorPage } from '@vat/components';
import { Header, Logo, OpenBanking, Redirection } from '@vat/layouts';
import React, { Suspense, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';

import {
  COOKIES,
  ERRORPAGE,
  LIVENESS,
  MENTIONSLEGALES,
  MENTIONSLEGALES100,
  MENTIONSLEGALES140,
  OBCONNEXIONERROR,
  OPENBANKING,
  REDIRECTION,
  REFUSEDREPONSE,
  SMSMENTIONLEGALE,
  SUCCESSREPONSE,
  WAITINGRESPONSE,
} from '@vat/configuration';

import {
  selectNavigation,
  selectSteps,
  useAppSelector,
} from '@sweb-front/store';
import { ObConnexionError } from '@vat/containers';
import {
  AppOptionsProvider,
  ErrorContext,
  PageLoadingContext,
  shouldForwardProp,
} from '@vat/utils';
import ApprovedPayment from '../ApprovedPayment';
import Cookies from '../Cookies/Cookies';
import ErrorPage from '../ErrorPage';
import Liveness from '../Liveness';
import MentionsLegales from '../MentionsLegales';
import RefusedPayment from '../RefusedPayment';
import SmsMentionLegale from '../SmsMentionLegale';
import WaitingPage from '../WaitingPage';
import WaitingResponse from '../WaitingResponse/WaitingResponse';
import {
  AppCenterContainer,
  AppWrapper,
  CenteringHeaderWrapper,
  RouteWrapper,
} from './styles';
import useApp, { buildRouteFromSteps, getStickyMessage } from './useApp';

const App: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const steps = useAppSelector(selectSteps);
  const opposedCommercialOffers = useAppSelector(
    (state) => state?.opportunity?.state?.person?.address?.useBo
  );
  const companyCode = useAppSelector(
    (state) => state?.opportunity?.state?.distributor?.companyCd
  );
  const channelCode = useAppSelector(
    (state) => state?.opportunity?.state?.distributionChannelCD
  );
  const appNavigation = useAppSelector(selectNavigation);
  const storedToken = localStorage.getItem('token');
  const [maskHeader, setMaskHeader] = useState<boolean>(false);

  const {
    t,
    currentStepWithSubStep,
    isOrderSummaryPage,
    appOptions,
    isErrorOccured,
    isPageWithNoHeader,
    lastPage,
    isAppLoading,
    numVendor,
    LoaderCmp,
    isWaitingPage,
    waitingStep,
    paramsWaitingPage,
    containedError,
    showFooter,
    showHeader,
    setAppOptions,
    updatePageIsLoading,
    updateError,
    computeStepsWithSubSteps,
    shouldDisplayRedirectionComponent,
  } = useApp({ searchParams, steps, appNavigation, storedToken });

  const themeConfig = getTheme();
  const MaskHeaderHandler = () => setMaskHeader(true);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Theme theme={themeConfig}>
        <AppOptionsProvider value={{ appOptions, setAppOptions }}>
          <AppWrapper data-testid="AppWrapper">
            <PageLoadingContext.Provider value={updatePageIsLoading}>
              <AppCenterContainer
                data-testid="AppCenterContainer"
                id="Main-container"
              >
                {showHeader &&
                  (themeConfig.themeName !== 'CETELEM' ||
                    (!maskHeader && !isOrderSummaryPage)) && (
                    <CenteringHeaderWrapper>
                      {themeConfig.themeName !== 'CETELEM' && (
                        <Logo numVendor={numVendor} />
                      )}
                      {!maskHeader && !isOrderSummaryPage && (
                        <Header
                          steps={computeStepsWithSubSteps(steps)}
                          currentStep={currentStepWithSubStep}
                        />
                      )}
                    </CenteringHeaderWrapper>
                  )}
                <GlobalStyles theme={themeConfig.themeName as any} />
                <base-ds-guidelines />
                <ErrorBoundary>
                  <Centering>
                    <ErrorContext.Provider value={updateError}>
                      {isAppLoading ? (
                        LoaderCmp
                      ) : isErrorOccured ? (
                        <ErrorPage />
                      ) : (
                        <RouteWrapper>
                          <Suspense fallback={LoaderCmp}>
                            {!isPageWithNoHeader &&
                            steps?.length <= 0 &&
                            !isWaitingPage ? (
                              LoaderCmp
                            ) : (
                              <Routes>
                                {buildRouteFromSteps(steps)}
                                <Route
                                  path="/to/:waitingStep"
                                  element={
                                    <WaitingPage
                                      waitingStep={waitingStep}
                                      paramsWaitingPage={
                                        paramsWaitingPage as TParamWaitingPage
                                      }
                                    />
                                  }
                                ></Route>
                                <Route
                                  path={SUCCESSREPONSE}
                                  element={<ApprovedPayment />}
                                />
                                <Route
                                  path={REFUSEDREPONSE}
                                  element={<RefusedPayment />}
                                />
                                <Route
                                  path={MENTIONSLEGALES}
                                  element={
                                    <MentionsLegales
                                      companyCode={parseInt(
                                        companyCode ?? '',
                                        10
                                      )}
                                    />
                                  }
                                />
                                <Route
                                  path={MENTIONSLEGALES100}
                                  element={
                                    <MentionsLegales companyCode={100} />
                                  }
                                />
                                <Route
                                  path={MENTIONSLEGALES140}
                                  element={
                                    <MentionsLegales companyCode={140} />
                                  }
                                />
                                <Route
                                  path={COOKIES}
                                  element={<Cookies />}
                                ></Route>
                                <Route
                                  path={WAITINGRESPONSE}
                                  element={<WaitingResponse />}
                                />
                                <Route
                                  path={SMSMENTIONLEGALE}
                                  element={<SmsMentionLegale />}
                                />
                                <Route
                                  path={OPENBANKING}
                                  element={<OpenBanking />}
                                />
                                <Route
                                  path={OBCONNEXIONERROR}
                                  element={<ObConnexionError />}
                                />

                                <Route
                                  path={LIVENESS}
                                  element={
                                    <Liveness
                                      token={localStorage.getItem('token')}
                                    />
                                  }
                                />
                                <Route
                                  path={REDIRECTION}
                                  element={<Redirection />}
                                />
                                <Route
                                  path={ERRORPAGE}
                                  element={<ErrorPage />}
                                />
                                <Route
                                  path="*"
                                  element={
                                    steps?.length <= 0 && !containedError ? (
                                      LoaderCmp
                                    ) : (
                                      <SpecificErrorPage
                                        handler={MaskHeaderHandler}
                                      />
                                    )
                                  }
                                />
                              </Routes>
                            )}
                            {showFooter && (
                              <Footer
                                companyCode={Number(companyCode)}
                                opposedCommercialOffers={
                                  opposedCommercialOffers
                                }
                              />
                            )}
                          </Suspense>
                        </RouteWrapper>
                      )}
                    </ErrorContext.Provider>
                  </Centering>
                </ErrorBoundary>
              </AppCenterContainer>
            </PageLoadingContext.Provider>
            {/*
            Displays when the the current page is a response page
            or an error occured or the session is expired
            and only for parcours online
           */}
            {shouldDisplayRedirectionComponent &&
              channelCode !== CHANELTYPE.POS && (
                <RedirectionComponent
                  delay={30}
                  title={t(getStickyMessage(lastPage)?.title)}
                  message={t(getStickyMessage(lastPage)?.message)}
                  currentPage={lastPage}
                  isErrorOccuredInPage={isErrorOccured}
                />
              )}
          </AppWrapper>
        </AppOptionsProvider>
      </Theme>
    </StyleSheetManager>
  );
};

export default App;
