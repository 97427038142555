import { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import {
  Background,
  StyledWrapper,
  ButtonClose,
  StyledCloseIcon,
  ButtonWrapper,
  ButtonPositionWrapper,
  ModalWrapper,
} from './styles';

export interface IModalProps {
  children?: React.ReactNode;
  handleCloseModal?: (event: any) => void;
  isVisible?: boolean;
  isClosableBtnDisabled?: boolean;
  isClosable?: boolean;
  className?: string;
  portalId?: string;
  isMobile?: boolean;
  isFullScreen?: boolean;
}

const Modal = ({
  children,
  handleCloseModal,
  isVisible = false,
  isClosable = false,
  isClosableBtnDisabled = false,
  className = '',
  portalId = 'modal',
  isMobile = true,
  isFullScreen = true,
}: IModalProps): React.ReactElement => {
  const { body } = document;
  const modalRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleKeyboardEvent = useCallback((event: Event) => {
    event.preventDefault();
    if ((event as KeyboardEvent).code === 'Escape') {
      handleCloseModal?.(event);
    }
  }, []);

  useEffect(() => {
    // Temp: If any developer find a better solution to it, she/he can replace this code.
    setTimeout(() => {
      if (isVisible && scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }, 10);
  }, [isVisible]);

  useEffect(() => {
    if (body) {
      body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
      if (isVisible) {
        body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';
        window.addEventListener('keydown', handleKeyboardEvent, false);
      }
    }

    return () => {
      if (body) {
        body.style.overflow = 'auto';
        document.documentElement.style.overflow = 'auto';
        window.removeEventListener('keydown', handleKeyboardEvent, false);
      }
    };
  }, [document, body, isClosable, isVisible]);

  return createPortal(
    <div ref={modalRef} className={className}>
      <Background
        id="modalContent"
        isVisible={isVisible}
        className={isVisible ? 'visible' : ''}
      >
        <StyledWrapper isMobile={isMobile} isFullScreen={isFullScreen}>
          {isClosable && (
            <ButtonWrapper
              className="main-close-wrapper"
              isFullScreen={isFullScreen}
            >
              <ButtonPositionWrapper>
                <ButtonClose
                  isDisabled={isClosableBtnDisabled}
                  onMouseDown={
                    isClosableBtnDisabled ? () => {} : handleCloseModal
                  }
                >
                  <StyledCloseIcon isDisabled={isClosableBtnDisabled} />
                </ButtonClose>
              </ButtonPositionWrapper>
            </ButtonWrapper>
          )}
          <ModalWrapper ref={scrollRef}>{children}</ModalWrapper>
        </StyledWrapper>
      </Background>
    </div>,
    document.getElementById(portalId) as HTMLElement
  );
};

export default Modal;
