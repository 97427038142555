import { useUrlWebMarchand } from '@sweb-front/hooks';
import {
  CONTEXT_ECONTRAT,
  CONTEXT_ECONTRAT_PROVISOIRE,
} from '@sweb-front/services';

import { CHANELTYPE, IRoutingContext } from '@sweb-front/types';
import {
  isStringEmpty,
  trackEvent,
  findPriOverdraftAmount,
} from '@sweb-front/utils';
import { IFinancialDetails } from '@sweb-front/types';
import { useCallback, useEffect, useContext, useMemo } from 'react';
import { setIdHashed } from '../../containers/forms/utils';
import { PageLoadingContext } from '@vat/utils';

const useWaitingResponse = (opportunity, parameters, navigation) => {
  const { getUrlLater } = useUrlWebMarchand();
  const { urlContractSpaceLink } = opportunity;
  const { routingContexts } = navigation;
  const updateIsLoading = useContext(PageLoadingContext);
  const onBackToWebMerchant = useCallback(() => {
    const urlLater = getUrlLater();
    if (urlLater) {
      window.location.href = urlLater;
    }
  }, [routingContexts]);

  const onContractClick = useCallback(() => {
    if (routingContexts && Array.isArray(routingContexts)) {
      const linkContract: IRoutingContext | undefined = routingContexts?.find(
        (routingContext: IRoutingContext) =>
          routingContext.contextUse === CONTEXT_ECONTRAT
      );
      const provisoryContractLink =
        routingContexts?.find(
          (rctxt: IRoutingContext) =>
            rctxt.contextUse === CONTEXT_ECONTRAT_PROVISOIRE
        ) ?? '';
      if (provisoryContractLink) {
        window.open(provisoryContractLink.url, '_blank');
      } else if (linkContract) {
        window.open(linkContract.url, '_blank');
      } else if (!isStringEmpty(urlContractSpaceLink)) {
        window.open(urlContractSpaceLink.url, '_blank');
      } else {
        console.warn('No url is provided!');
      }
    }
  }, [routingContexts]);

  useEffect(() => {
    let financialDetails = {} as IFinancialDetails;
    if (opportunity && opportunity.offers?.length > 0) {
      financialDetails = opportunity.offers[0].loans[0].financialDetails;
    }
    const isMono: boolean = opportunity?.bagType === 'MONO';
    trackEvent({
      event: 'module_interaction',
      pageName: 'E-Commerce : FO',
      site: 'Ecommerce',
      workflow: parameters?.wayCd,
      // eslint-disable-next-line no-underscore-dangle
      environment: (window as unknown as WindowWithEnv)._env_?.env,
      visitorStatus: 'non-logged',
      Amount: (isMono
        ? financialDetails?.overdraftAmt
        : findPriOverdraftAmount(opportunity?.offers?.[0]?.loans)
      )?.toString(),
      Rate: isMono ? financialDetails?.tncRt?.toString() : undefined,
      Term: isMono ? financialDetails?.term?.toString() : undefined,
      MonthlyPayment: isMono
        ? financialDetails?.monthlyPaymentWithoutInsuranceAmt?.toString()
        : undefined,
      opportunityIdHashed: setIdHashed(opportunity),
      ContributorCode: opportunity?.distributor?.distributorNb ?? '',
      pathType: isMono ? 'mono panier' : 'multi panier',
    });

    updateIsLoading(false);
  }, []);

  const deliveryMode = useMemo(() => {
    const { distributionChannelCD } = opportunity;
    if (distributionChannelCD !== CHANELTYPE.POS) {
      return opportunity.project?.[0]?.deliveryMode ?? 'STH'; // STH default value(based on maquette)
    }
    return distributionChannelCD;
  }, [opportunity]);

  return {
    onBackToWebMerchant,
    onContractClick,
    deliveryMode,
  };
};

export default useWaitingResponse;
