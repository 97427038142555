/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { Icons } from '@sweb-front/components';
import { RowCenterWrapper } from '../CenterContainer/styles';
import { InfoGlyphIcon } from '../Icons';
import { CrossCircleIcon } from '../Icons';

export const ComboInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
`;

export const InputLabel = styled.span<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.vendor.colors.disabled : theme.vendor.colors.text};
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`;

export const UnstyledInput = styled.input`
  border: none;
  background-image: none;
  background-color: transparent;
  color: var(--neutrals-500);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
  }
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0.1rem;
  width: 100%;
  height: 95%;
  padding-left: 1.6rem;
  &::placeholder {
    color: var(--neutral-300);
  }
  &.search-icon {
    padding-left: calc(1.6rem - 0.8rem);
  }
  text-overflow: ellipsis;
`;

export const InputContainer = styled.div<{
  disabled: boolean;
  isError: boolean;
  isValid: boolean;
  isTouched: boolean;
}>`
  position: relative;
  border: 1px solid
    ${({ theme, disabled, isError, isValid, isTouched }) => {
      if (disabled) {
        return theme.vendor.colors.disabled;
      }
      if (isError) {
        return theme.vendor.colors.error;
      }
      if (isValid && isTouched) {
        return theme.vendor.colors.primary;
      }
      return theme.vendor.colors.border;
    }};
  border-radius: 4px;
  height: 4.8rem;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.vendor.colors.disabledBackground : '#ffffff'};
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  flex-grow: 1;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};
  &:focus {
    border: 1px solid var(--dark-green-400);
  }
`;

export const ChevronUpIconWrapper = styled((props) => (
  <Icons.ChevronUpIcon {...props} />
))<{
  isOpened: boolean;
}>`
  width: 2rem;
  height: 2.4rem;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transform: ${({ isOpened }) =>
    isOpened ? 'rotate(0deg)' : 'rotate(180deg)'};
`;

export const InfoGlyphIconWrapper = styled(InfoGlyphIcon)`
  height: 2.4rem;
`;

export const CrossCircleWrapper = styled(CrossCircleIcon)`
  height: 2.4rem;
`;

export const CrossWrapper = styled.button`
  height: 2.4rem;
  cursor: pointer;
  border: none;
  background: transparent;
`;

export const IconWrapper = styled.div<{ disabled: boolean }>`
  height: 100%;
  width: 7.5rem;
  display: flex;
  padding: 0 1rem;
  flex-direction: column;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const ComboInputRowCenterWrapper = styled(RowCenterWrapper)`
  column-gap: 0.5rem;
`;

export const ComboList = styled.ul<{
  isOpened: boolean;
  isError: boolean;
  isValid: boolean;
  filteredOptionsLength: number;
}>`
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transform: translate(0, 4.7rem)
    ${({ isOpened }) => (isOpened ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top;
  position: absolute;
  list-style-type: none;
  z-index: 50;
  margin: 0.8rem 0 0 0;
  padding: 0;
  width: 100%;
  height: calc(${({ filteredOptionsLength }) => filteredOptionsLength}*4.4rem);
  max-height: 23rem;
  border: 1px solid
    ${({ theme, isError, isValid }) =>
      (() => {
        if (isError) {
          return theme.vendor.colors.error;
        }
        if (isValid) {
          return theme.vendor.colors.primary;
        }
        return theme.vendor.colors.border;
      })()};
  border-radius: 4px;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px 2px 10px 0px #00000026;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: ${({ filteredOptionsLength }) =>
    filteredOptionsLength > 3 ? 'scroll' : 'auto'};
  &.search-icon {
    border: none;
  }
  &.no-result {
    min-height: 4rem;
    padding: 0;
  }
`;

export const ComboListItem = styled.li<{ hovered: boolean }>`
  padding: 1.2rem 1.6rem;
  &:hover {
    background-color: var(--main-green-50);
    cursor: pointer;
  }
  &:not(:last-child)::after {
    display: block;
    position: relative;
    top: 1.25rem;
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--neutrals-200);
  }
  &.active {
    position: sticky;
    bottom: 0;
    background-color: var(--neutrals-0);
    &:hover {
      background-color: var(--main-green-50);
      cursor: pointer;
    }
  }
  &.no-result {
    padding: 0.8rem 0 0.8rem 1.6rem;
  }
`;

export const ComboListItemText = styled.span`
  user-select: none;
`;

export const ErrorMessageWrapper = styled.span`
  color: ${({ theme }) => theme.vendor.colors.error};
`;

export const FallBackTextWrapper = styled.a`
  color: ${({ theme }) => theme.vendor.colors.primary};
  height: 4rem;
  text-decoration: underline;
  cursor: pointer;
`;

export const FirstLineOption = styled.div`
  color: var(--neutrals-500);
  font-family: 'Open Sans';
  font-size: 1.4rem;
`;

export const SecondLineOption = styled.div`
  color: ${({ theme }) => theme.vendor.colors.text};
  font-family: 'Open Sans';
  font-size: 1.2rem;
  line-height: 17px;
`;

export const Separator = styled.span`
  border-top: 1px solid ${({ theme }) => theme.vendor.colors.stepline};
  width: 80%;
  height: 1px;
  display: block;
  margin-bottom: 10rem;
`;

export const BaseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.4rem;
`;
