export type ReloadNavigationProps = {
  fill?: string;
};

const ReloadNavigation = ({ fill = 'currentColor' }: ReloadNavigationProps) => {
  return (
    <svg width="24" height="19" viewBox="0 0 24 19" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 1.53913C9.58618 1.53913 7.42752 2.62945 6.00138 4.3428C5.82798 4.56567 5.54589 4.67713 5.26545 4.6336C4.985 4.59007 4.75075 4.39846 4.65433 4.13372C4.5579 3.86897 4.61462 3.57316 4.8023 3.36198C7.12774 0.570167 10.8814 -0.620681 14.4092 0.314169C17.9371 1.24902 20.5912 4.13788 21.2022 7.70783L23.6113 7.70783C23.7622 7.70788 23.8993 7.79442 23.9633 7.92986C24.0273 8.0653 24.0065 8.2252 23.9099 8.34012L20.8524 11.9796C20.7785 12.0675 20.6691 12.1183 20.5538 12.1183C20.4384 12.1183 20.329 12.0675 20.2551 11.9796L17.1976 8.34012C17.101 8.2252 17.0802 8.0653 17.1442 7.92986C17.2082 7.79442 17.3454 7.70788 17.4962 7.70783L19.6205 7.70783C18.8803 4.11779 15.6952 1.53958 11.9999 1.53913ZM6.85579 10.5701C6.79183 10.7056 6.65464 10.7921 6.50382 10.7922H0.388666C0.237845 10.7921 0.100663 10.7056 0.0367011 10.5701C-0.0272611 10.4347 -0.0064631 10.2748 0.0900622 10.1599L3.14764 6.52035C3.22151 6.43251 3.3309 6.38173 3.44625 6.38173C3.56159 6.38173 3.67098 6.43251 3.74485 6.52035L6.80243 10.1599C6.89895 10.2748 6.91975 10.4347 6.85579 10.5701ZM10.1196 16.73C7.21279 16.0118 4.98015 13.7023 4.37927 10.7922H2.79761C3.40857 14.3621 6.06273 17.251 9.59057 18.1858C13.1184 19.1207 16.872 17.9298 19.1975 15.138C19.3852 14.9268 19.4419 14.631 19.3455 14.3663C19.249 14.1015 19.0148 13.9099 18.7343 13.8664C18.4539 13.8229 18.1718 13.9343 17.9984 14.1572C16.0908 16.4475 13.0263 17.4482 10.1196 16.73Z"
        fill={fill}
      />
    </svg>
  );
};
export default ReloadNavigation;
