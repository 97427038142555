import { setModalClosedStyle, setModalOpenedStyle } from '@vat/containers';
import { useState } from 'react';

export const useModal = () => {
  const [isOpened, setIsOpened] = useState(false);

  const openModal = () => {
    setIsOpened(true);
    setModalOpenedStyle();
  };

  const closeModal = () => {
    setIsOpened(false);
    setModalClosedStyle();
  };

  return {
    isOpened,
    openModal,
    closeModal,
  };
};
