
import { EventAnalytic, ILoan } from '@sweb-front/types';

export const findPriOverdraftAmount = (loans: ILoan[]): number => {
  return loans?.find((loan) => loan.typeCd === 'PRI')?.financialDetails
    .overdraftAmt!;
};

export const trackEvent = (event: EventAnalytic) => {
  console.log('DataLayer =>', (window as any).dataLayer);
  console.log('Event => ', event);

  (window as any).dataLayer.push(event);
};
