import { IOpportunityState, IParameterState } from '@sweb-front/store';
import { findPriOverdraftAmount, trackEvent } from '@sweb-front/utils';
import { IFinancialDetails } from '@sweb-front/types';
import { SCORE } from '@vat/configuration';
import { setIdHashed } from '../../containers/forms/utils';
import { useContext, useEffect } from 'react';
import { PageLoadingContext } from '@vat/utils';

const useRefusedPayment = (
  opportunity: IOpportunityState,
  parameters: IParameterState
) => {
  const { wayType } = parameters;
  const updateIsLoading = useContext(PageLoadingContext);

  const getFinancialDetails = (opportunity) => {
    if (opportunity && opportunity.offers?.length > 0) {
      return opportunity.offers[0].loans[0].financialDetails;
    }
    return {} as IFinancialDetails;
  };

  const trackOpportunityEvent = (opportunity, parameters, financialDetails) => {
    const isMono: boolean = opportunity?.bagType === 'MONO';
    trackEvent({
      event: 'module_interaction',
      pageName: `E-Commerce : FR${localStorage.getItem(SCORE) ? ' final' : ''}`,
      site: 'Ecommerce',
      workflow: parameters?.wayCd,
      // eslint-disable-next-line no-underscore-dangle
      environment: (window as unknown as WindowWithEnv)._env_?.env,
      visitorStatus: 'non-logged',
      Amount: (isMono
        ? financialDetails?.overdraftAmt
        : findPriOverdraftAmount(opportunity?.offers?.[0]?.loans)
      )?.toString(),
      Rate: isMono ? financialDetails?.tncRt?.toString() : undefined,
      Term: isMono ? financialDetails?.term?.toString() : undefined,
      MonthlyPayment: isMono
        ? financialDetails?.monthlyPaymentWithoutInsuranceAmt?.toString()
        : undefined,
      opportunityIdHashed: setIdHashed(opportunity),
      ContributorCode: opportunity?.distributor?.distributorNb ?? '',
      pathType: isMono ? 'mono panier' : 'multi panier',
    });
  };

  useEffect(() => {
    const financialDetails = getFinancialDetails(opportunity);
    trackOpportunityEvent(opportunity, parameters, financialDetails);
    updateIsLoading(false);
  }, []);

  return {
    wayType,
  };
};

export default useRefusedPayment;
