import { Modal } from '@sweb-front/components';
import { ModalLeaveWrapper, StyledValidateButtonWrapper } from '@vat/utils';
import {
  ButtonLeaveModalWrapper,
  DescriptionWrapper,
  LeaveContentWrapper,
  SVGIconReturnWrapper,
  TitleWrapper,
} from './styles';
import { t } from 'i18next';
import { StyledButtonLoader } from '@sweb-front/styles';
import useModalBadFormat from './useModalBadFormat';
import { ReactNode } from 'react';

export interface IMoodalBadFormatProps {
  title: ReactNode;
  description: ReactNode;
  close?: () => void;
}

const ModalBadFormat = (props?: IMoodalBadFormatProps) => {
  const { returnToUrlWebMerchant, isLoading } = useModalBadFormat();
  return (
    <>
      <Modal isVisible={true} isFullScreen={false} isClosable={false}>
        <ModalLeaveWrapper>
          <LeaveContentWrapper>
            <TitleWrapper level={3}>{props.title}</TitleWrapper>
            <DescriptionWrapper>{props.description}</DescriptionWrapper>
          </LeaveContentWrapper>
          <ButtonLeaveModalWrapper>
            <StyledValidateButtonWrapper
              onClick={returnToUrlWebMerchant}
              id="modal-edit-button"
            >
              {isLoading ? (
                <StyledButtonLoader
                  isLoading
                  isTextInline
                  isHideBackground={false}
                />
              ) : (
                <>
                  <SVGIconReturnWrapper></SVGIconReturnWrapper>
                  {t('basicInformations.errors.modalBadFormat.leaveMessage')}
                </>
              )}
            </StyledValidateButtonWrapper>
          </ButtonLeaveModalWrapper>
        </ModalLeaveWrapper>
      </Modal>
    </>
  );
};

export default ModalBadFormat;
