import {
  BubbleInfo,
  CustomerInformationCard,
  Modal,
} from '@sweb-front/components';
import {
  AutoCompleteField,
  ComboInput,
  CookiesCheckbox,
  DesktopSelect,
  Modal as InformationModal,
  InputDate,
  InputText,
  Modal as ModalKYC,
  NavigationButtons,
  RadioGroup,
  SelectInputWithoutOption,
  useModal,
} from '@vat/components';
import { INFORMATIONPAGE } from '@vat/configuration';
import {
  ModalWrapper,
  StyledModalCancelButtonWrapper,
  StyledModalValidateButtonWrapper,
  capitalize,
  setAllFieldsTouched,
} from '@vat/utils';

import { useAppSelector } from '@sweb-front/store';
import { StyledButtonLoader } from '@sweb-front/styles';
import { ModalBadFormat, ModalLeave } from '@vat/layouts';
import { useState } from 'react';
import { ButtonLabel } from 'src/components/NavigationButtons/styles';
import { SpanLabel, ValidateLabelWrapper } from 'src/libs/styles/globalStyles';
import { scrollToErrorMessage } from '../utils';
import { InformationFormProps } from './InformationForm';
import {
  ActivitySectorContainer,
  ActivitySectorTitle,
  BubbleInfoBirthNameWrapper,
  BubbleInfoLastNameWrapper,
  ButtonModalWrapper,
  ContentWrapper,
  DescriptionWrapper,
  FormWrapper,
  InputWrapper,
  ModalRadioGroupContainer,
  SVGIconErrorWrapper,
  SVGIconReturnWrapper,
  StyledTitle,
  TitleContent,
  TitleWrapper,
} from './Styles';
import useInformationForm from './useInformationForm';
import { Trans } from 'react-i18next';

export const InformationFormWestCoast = ({ fields }: InformationFormProps) => {
  const referentiels = useAppSelector((state) => state.referentiel.state);
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const parameters = useAppSelector((state) => state.parameters.state);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const { isOpened, openModal, closeModal } = useModal();
  const [formikValidityState, setFormikValidityState] = useState(false);
  const hasKycBloc4 = fields.includes(INFORMATIONPAGE.MKYCIDEN);

  const {
    formik,
    setBirthDepartment,
    isDesktop,
    resetBirthCity,
    t,
    showAutoCompletedField,
    autocompleteBirthCity,
    isBirthCountryFR,
    isPopUpEditOpened,
    closePopUpEdit,
    isPopUpLeaveOpened,
    openPopUpLeave,
    closePopUpLeave,
    returnToUrlWebMerchant,
    goBackToStep,
    currentStep,
    sectors,
    propCard,
    onUpdateOptin,
    onUpdateDopc,
    dopcOptin,
    isActionLoading,
    onChangeAutoCompletedField,
    onSuggestionAutoCompletedFieldClick,
    onBlurAutocomplete,
    defaultAutocompleteValue,
    isBirthNameFocused,
    setIsBirthNameFocused,
    isLastNameFocused,
    onLastNameFocus,
    onLastNameBlur,
    isFirstNameFocused,
    onFirstNameFocus,
    onFirstNameBlur,
    showModalLeave,
    onShowModalLeaveFn,
    isShowModalBadFormat,
    badFormatData,
  } = useInformationForm({
    opportunity,
    referentiels,
    parameters,
    fields,
  });

  const getInvalidityState = (trigger: boolean = false) => {
    if (trigger) {
      return formik?.values?.autoCompleteField !== '';
    }
  };

  const handleClick = () => {
    if (!spinnerLoading) {
      setSpinnerLoading(true);
      try {
        returnToUrlWebMerchant();
      } catch (error) {
        console.error('Error while try handleClick method', error);
      }
    }
  };

  return (
    <>
      <FormWrapper id="information-form">
        {(fields.includes(INFORMATIONPAGE.KYCBLOC1) ||
          fields.includes(INFORMATIONPAGE.MKYCIDEN)) && (
          <>
            <RadioGroup
              id="title"
              key={`title-touched:${formik.touched.title}`}
              label={t('basicInformations.civility.label')}
              value={formik.values.title}
              errorMessage={formik.errors.title}
              buttonWidth="48.5%"
              onChange={(value) => formik.setFieldValue('title', value.value)}
              options={[
                {
                  label: t('basicInformations.civility.OptionFemale'),
                  value: 'MME',
                },
                {
                  label: t('basicInformations.civility.OptionMale'),
                  value: 'MR',
                },
              ]}
              rowGap="0rem"
              columnGap="0rem"
              touched={formik.touched.title}
            />
          </>
        )}
        {fields.includes(INFORMATIONPAGE.MKYCIDEN) && (
          <>
            <BubbleInfoLastNameWrapper>
              <InputText
                id="lastName"
                autoComplete="family-name"
                key={`lastName-touched:${formik.touched.lastName}`}
                label={t('basicInformations.lastName')}
                value={formik.values.lastName}
                onChange={(value) =>
                  formik.setFieldValue('lastName', capitalize(value))
                }
                maxLength={30}
                placeholder={t('basicInformations.placeHolder.lastName')}
                errorMessage={formik.errors.lastName}
                onBlur={onLastNameBlur}
                touched={formik.touched.lastName}
                onFocus={onLastNameFocus}
                onInput={(value) => {
                  if (formik.dirty && formik.isValid) {
                    formik.setFieldTouched('lastName', value ? true : false);
                  }
                }}
              />
              {isLastNameFocused && !formik.errors.lastName && (
                <BubbleInfo
                  id="bubbleinfo-information-form"
                  title={t('basicInformations.bubbleInfo.title')}
                  secondTitle={t('basicInformations.bubbleInfo.secondTitle')}
                  marker="default"
                  strongTitle={t('basicInformations.bubbleInfo.lastName')}
                  isCustomedMarker={false}
                  textColor="#007590"
                />
              )}
            </BubbleInfoLastNameWrapper>
            <BubbleInfoLastNameWrapper>
              <InputText
                id="firstName"
                label={t('basicInformations.firstName')}
                key={`firstName-touched:${formik.touched.firstName}`}
                value={formik.values.firstName}
                autoComplete="given-name"
                onChange={(value) =>
                  formik.setFieldValue('firstName', capitalize(value))
                }
                maxLength={30}
                placeholder={t('basicInformations.placeHolder.firstName')}
                errorMessage={formik.errors.firstName}
                onBlur={onFirstNameBlur}
                touched={formik.touched.firstName}
                onFocus={onFirstNameFocus}
                onInput={(value) => {
                  if (formik.dirty && formik.isValid) {
                    formik.setFieldTouched('firstName', value ? true : false);
                  }
                }}
              />
              {isFirstNameFocused && !formik.errors.firstName && (
                <BubbleInfo
                  id="bubbleinfo-information-form"
                  title={t('basicInformations.bubbleInfo.title')}
                  secondTitle={t('basicInformations.bubbleInfo.secondTitle')}
                  marker="default"
                  strongTitle={t('basicInformations.bubbleInfo.firstName')}
                  isCustomedMarker={false}
                  textColor="#007590"
                />
              )}
            </BubbleInfoLastNameWrapper>
          </>
        )}
        {fields.includes(INFORMATIONPAGE.KYCNOMNAI) && (
          <BubbleInfoBirthNameWrapper>
            <InputText
              id="birthName"
              autoComplete="family-name"
              key={`birthName-touched:${formik.touched.birthName}`}
              label={t('basicInformations.birthName')}
              placeholder={t('basicInformations.placeHolder.birthName')}
              value={formik.values.birthName}
              onChange={(value) =>
                formik.setFieldValue('birthName', capitalize(value))
              }
              errorMessage={formik.errors.birthName}
              onBlur={(e) => {
                setIsBirthNameFocused(false);
                formik.handleBlur(e);
              }}
              touched={formik.touched.birthName}
              maxLength={30}
              onFocus={() => setIsBirthNameFocused(true)}
              onInput={(value) => {
                if (formik.dirty && formik.isValid) {
                  formik.setFieldTouched('birthName', value ? true : false);
                }
              }}
            />
            {isBirthNameFocused && !formik.errors.birthName && (
              <BubbleInfo
                id="bubbleinfo-information-store-form"
                title={t('basicInformations.bubbleInfo.title')}
                secondTitle={t('basicInformations.bubbleInfo.secondTitle')}
                marker="default"
                strongTitle={t('basicInformations.bubbleInfo.birthName')}
                isCustomedMarker={false}
                textColor="#007590"
              />
            )}
          </BubbleInfoBirthNameWrapper>
        )}
        {fields.includes(INFORMATIONPAGE.KYCDTNAI) && (
          <InputDate
            id="birthDt"
            autoComplete={{
              day: 'bday-day',
              month: 'bday-month',
              year: 'bday-year',
            }}
            key={`birthDt-touched:${formik.touched.birthDt}`}
            label={t('basicInformations.birthDate')}
            value={formik.values.birthDt}
            onChange={(value) => formik.setFieldValue('birthDt', value)}
            errorMessage={formik.errors.birthDt}
            onBlur={() => formik.setFieldTouched('birthDt', true)}
            touched={formik.touched.birthDt}
          />
        )}
        {showAutoCompletedField ? (
          <AutoCompleteField
            onChange={(res, concatenedRes) => {
              onChangeAutoCompletedField(res, concatenedRes);
              setFormikValidityState(false);
            }}
            label={t('basicInformations.birthCity')}
            id="autoCompleteField"
            value={autocompleteBirthCity ?? defaultAutocompleteValue}
            url="/localities/v1/cities"
            required
            suggestionLabel={t('common.countryNotFound')}
            placeholder={t(
              'basicInformations.placeHolder.autocompleteBirthCity'
            )}
            onBlur={onBlurAutocomplete}
            onSuggestionClick={() => {
              onSuggestionAutoCompletedFieldClick();
            }}
            emptyErrorMessage={t(
              'basicInformations.errors.autocompleteBirthCity.emptyValue'
            )}
            invalidErrorMessage={t(
              'basicInformations.errors.autocompleteBirthCity.invalidValue'
            )}
            valid={getInvalidityState(formikValidityState)}
          />
        ) : (
          <>
            {fields.includes(INFORMATIONPAGE.KYCPAYSNAI) && (
              <ComboInput
                id="birthCountryIsoCd"
                key={`comboInput-birthCountry-${referentiels?.countries?.length}-touched:${formik.touched.birthCountryIsoCd}`}
                label={t('basicInformations.birthCountry')}
                placeholder={t('basicInformations.placeHolder.birthCountry')}
                errorMessage={formik.errors.birthCountryIsoCd}
                value={formik.values.birthCountryIsoCd}
                autoComplete="birth-country"
                options={referentiels?.countries?.map((country) => ({
                  value: country.test,
                  label: country.name,
                }))}
                touched={formik.touched.birthCountryIsoCd}
                onChange={(item) => {
                  resetBirthCity();
                  formik.setFieldValue('birthCountryIsoCd', item?.value);
                }}
                onBlur={formik.handleBlur}
                onInput={(value) => {
                  if (formik.dirty && formik.isValid) {
                    formik.setFieldTouched(
                      'birthCountryIsoCd',
                      value ? true : false
                    );
                  }
                }}
              />
            )}
            {fields.includes(INFORMATIONPAGE.KYCPAYSNAI) &&
              isBirthCountryFR && (
                <ComboInput
                  id="birthDepartment"
                  key={`comboInput-birthDepartment-${referentiels?.departements?.length}-touched:${formik.touched.birthDepartment}`}
                  label={t('basicInformations.birthDepartment')}
                  placeholder={t(
                    'basicInformations.placeHolder.birthDepartment'
                  )}
                  errorMessage={formik.errors.birthDepartment}
                  value={formik.values.birthDepartment}
                  autoComplete="birth-department"
                  options={referentiels?.departements?.map((department) => ({
                    value: department.test,
                    label: department.name,
                  }))}
                  touched={formik.touched.birthDepartment}
                  onChange={(item) => setBirthDepartment(item?.value)}
                  onBlur={formik.handleBlur}
                  onInput={(value) => {
                    if (formik.dirty && formik.isValid) {
                      formik.setFieldTouched(
                        'birthDepartment',
                        value ? true : false
                      );
                    }
                  }}
                />
              )}
            {fields.includes(INFORMATIONPAGE.KYCVILNAI) &&
              (isBirthCountryFR ? (
                <ComboInput
                  id="birthCity"
                  key={`comboInput-birthCity-${referentiels?.cities?.length}-touched:${formik.touched.birthCity}`}
                  label={t('basicInformations.birthCity')}
                  placeholder={t('basicInformations.placeHolder.birthCity')}
                  errorMessage={formik.errors.birthCity}
                  value={formik.values.birthCityInseeCd}
                  autoComplete="birth-city"
                  options={referentiels?.cities?.map((city) => ({
                    value: city.test,
                    label: city.name,
                  }))}
                  touched={formik.touched.birthCity}
                  onChange={(item) => {
                    formik.setFieldValue('birthCityInseeCd', item?.value);
                    formik.setFieldValue('birthCity', item?.label);
                  }}
                  onBlur={formik.handleBlur}
                  onInput={(value) => {
                    if (formik.dirty && formik.isValid) {
                      formik.setFieldTouched(
                        'birthCityInseeCd',
                        value ? true : false
                      );
                      formik.setFieldTouched('birthCity', value ? true : false);
                    }
                  }}
                />
              ) : (
                <InputText
                  id="birthCity"
                  key={`inputText-birthCity-${referentiels?.cities?.length}-touched:${formik.touched.birthCity}`}
                  label={t('basicInformations.birthCity')}
                  placeholder={t('basicInformations.placeHolder.birthCity')}
                  errorMessage={formik.errors.birthCity}
                  value={formik.values.birthCity}
                  autoComplete="off"
                  touched={formik.touched.birthCity}
                  onChange={(v) => formik.setFieldValue('birthCity', v)}
                  onBlur={formik.handleBlur}
                  onInput={(value) => {
                    if (formik.dirty && formik.isValid) {
                      formik.setFieldTouched('birthCity', value ? true : false);
                    }
                  }}
                />
              ))}
          </>
        )}
        <ComboInput
          id="nationalityIsoCd"
          key={`comboInput-nationalityIsoCd-${referentiels?.nationalities?.length}-touched:${formik.touched.nationalityIsoCd}`}
          label={t('basicInformations.nationality')}
          placeholder={t('basicInformations.placeHolder.nationality')}
          errorMessage={formik.errors.nationalityIsoCd}
          value={formik.values.nationalityIsoCd}
          autoComplete="nationalityIsoCd"
          options={referentiels?.nationalities?.map((nationality) => ({
            value: nationality.test,
            label: nationality.name,
          }))}
          touched={formik.touched.nationalityIsoCd}
          onChange={(item) =>
            formik.setFieldValue('nationalityIsoCd', item?.value)
          }
          onBlur={formik.handleBlur}
          onInput={(value) => {
            if (formik.dirty && formik.isValid) {
              formik.setFieldTouched('nationalityIsoCd', value ? true : false);
            }
          }}
        />
        {fields.includes(INFORMATIONPAGE.KYCSOLVACT) && (
          <ActivitySectorContainer>
            <ActivitySectorTitle>
              {t('basicInformations.activitySectorsTitle')}
            </ActivitySectorTitle>
            {isDesktop ? (
              <DesktopSelect
                id="activitySector"
                label={t('basicInformations.activitySectors')}
                placeholder={t('basicInformations.placeHolder.activitySectors')}
                options={sectors}
                errorMessage={formik.errors.activitySector}
                value={formik.values.activitySector}
                onChange={(value) =>
                  formik.setFieldValue('activitySector', value)
                }
                onClose={() => formik.setFieldTouched('activitySector')}
                touched={formik.touched.activitySector}
              />
            ) : (
              <SelectInputWithoutOption
                id="activitySector"
                onClick={() => openModal()}
                errorMessage={formik.errors.activitySector}
                label={t('basicInformations.activitySectors')}
                placeholder={t('basicInformations.placeHolder.activitySectors')}
                value={
                  sectors.find(
                    ({ value }) => value === formik.values.activitySector
                  )?.label
                }
                touched={formik.touched.activitySector}
              />
            )}
          </ActivitySectorContainer>
        )}
        {(fields.includes(INFORMATIONPAGE.MKYCADR1) ||
          fields.includes(INFORMATIONPAGE.MKYCMAIL) ||
          fields.includes(INFORMATIONPAGE.MKYCTEL)) && (
          <StyledTitle>
            {t('basicInformations.contactDetails.title')}
          </StyledTitle>
        )}
        {fields.includes(INFORMATIONPAGE.MKYCADR1) && (
          <>
            <InputText
              id="address"
              key={`information-address-${formik.touched.address}`}
              label={t('basicInformations.contactDetails.address.label')}
              placeholder={t(
                'basicInformations.contactDetails.address.placeholder'
              )}
              value={formik.values.address}
              onChange={(value) => formik.setFieldValue('address', value)}
              maxLength={39}
              errorMessage={formik.errors.address}
              onBlur={formik.handleBlur}
              touched={formik.touched.address}
              autoComplete="address-line1"
              onInput={(value) => {
                if (formik.dirty && formik.isValid) {
                  formik.setFieldTouched('address', value ? true : false);
                }
              }}
            />
            <InputText
              id="complement"
              key={`information-complement-${formik.touched.complement}`}
              label={t('basicInformations.contactDetails.complement.label')}
              placeholder={t(
                'basicInformations.contactDetails.complement.placeholder'
              )}
              optional
              errorMessage={formik.errors.complement}
              value={formik.values.complement}
              autoComplete="complement"
              touched={formik.touched.complement}
              onBlur={formik.handleBlur}
              maxLength={39}
              onChange={(value) => formik.setFieldValue('complement', value)}
              onInput={(value) =>
                formik.setFieldTouched('complement', value ? true : false)
              }
            />
            <InputText
              id="postalCode"
              key={`information-postalCode-${formik.touched.postalCode}`}
              label={t('basicInformations.contactDetails.postalCode.label')}
              placeholder={t(
                'basicInformations.contactDetails.postalCode.placeholder'
              )}
              value={formik.values.postalCode}
              onChange={(value) => formik.setFieldValue('postalCode', value)}
              maxLength={6}
              errorMessage={formik.errors.postalCode}
              onBlur={formik.handleBlur}
              touched={formik.touched.postalCode}
              autoComplete="postal-code"
              onInput={(value) => {
                if (formik.dirty && formik.isValid) {
                  formik.setFieldTouched('postalCode', value ? true : false);
                }
              }}
            />
            <InputText
              id="city"
              key={`information-city-${formik.touched.city}`}
              label={t('basicInformations.contactDetails.city.label')}
              placeholder={t(
                'basicInformations.contactDetails.city.placeholder'
              )}
              errorMessage={formik.errors.city}
              onChange={(value) => formik.setFieldValue('city', value)}
              value={formik.values.city}
              touched={formik.touched.city}
              onBlur={formik.handleBlur}
              autoComplete="address-level2"
              onInput={(value) => {
                if (formik.dirty && formik.isValid) {
                  formik.setFieldTouched('city', value ? true : false);
                }
              }}
            />
          </>
        )}
        {fields.includes(INFORMATIONPAGE.MKYCMAIL) && (
          <InputText
            id="email"
            type="email"
            key={`information-store-email-${formik.touched.email}`}
            label={t('basicInformations.contactDetails.email.label')}
            placeholder={t(
              'basicInformations.contactDetails.email.placeholder'
            )}
            errorMessage={formik.errors.email}
            value={formik.values.email}
            autoComplete="email"
            touched={formik.touched.email}
            onBlur={formik.handleBlur}
            maxLength={50}
            onChange={(value) => formik.setFieldValue('email', value)}
            onInput={(value) => {
              if (formik.dirty && formik.isValid) {
                formik.setFieldTouched('email', value ? true : false);
              }
            }}
          />
        )}
        {fields.includes(INFORMATIONPAGE.MKYCTEL) && (
          <InputText
            id="mobile"
            key={`information-store-mobile-${formik.touched.mobile}`}
            label={t('basicInformations.contactDetails.mobile.label')}
            placeholder={t(
              'basicInformations.contactDetails.mobile.placeholder'
            )}
            errorMessage={formik.errors.mobile}
            value={formik.values.mobile}
            inputMode="numeric"
            autoComplete="tel-national"
            touched={formik.touched.mobile}
            onBlur={formik.handleBlur}
            onChange={(value) => {
              const numRegex = /^\d+$/;
              // TODO : avoid test below, enhance ZOD schema to put InputNumber instead InputText
              // INFO : New feature will be created for this
              if (numRegex.test(value) || value === '') {
                formik.setFieldValue('mobile', value);
              } else {
                if (value?.length === 1) {
                  formik.setFieldValue('mobile', '');
                }
              }
            }}
            onInput={(value) => {
              if (formik.dirty && formik.isValid) {
                formik.setFieldTouched('mobile', value ? true : false);
              }
            }}
          />
        )}

        {(fields.includes(INFORMATIONPAGE.KYCBLOC2) ||
          fields.includes(INFORMATIONPAGE.KYCBLOC1) ||
          fields.includes(INFORMATIONPAGE.NMKYCMAIL) ||
          fields.includes(INFORMATIONPAGE.NMKYCTEL)) && (
          <CustomerInformationCard props={propCard} />
        )}
      </FormWrapper>
      <InputWrapper
        data-testid="inputWrapperInformationStoreForm"
        id="inputWrapperInformationStoreForm"
      >
        <CookiesCheckbox
          id="optin"
          name="optin"
          label={t('basicInformations.cookiesConsent')}
          state={dopcOptin.optin}
          onChange={onUpdateOptin}
        />
        <CookiesCheckbox
          id="dopc"
          name="dopc"
          label={t('basicInformations.dopcNoConsent')}
          state={dopcOptin.dopc}
          onChange={onUpdateDopc}
        />
      </InputWrapper>
      <NavigationButtons
        id="navigation-buttons"
        key={`nav-buttons-${
          formik.dirty && formik.isValid ? 'isValid' : 'isInvalid'
        }`}
        validateLabel={t('common.validate')}
        isLoading={isActionLoading}
        validateAction={() => {
          setAllFieldsTouched(formik.values, formik.setTouched);
          formik.handleSubmit();
          if (
            formik.values.birthDepartment === undefined &&
            formik.values.birthCountryIsoCd === undefined &&
            formik.values.birthCity === undefined
          ) {
            setFormikValidityState(true);
          }
          setTimeout(() => scrollToErrorMessage(), 0);
        }}
        backLabel={t('common.back')}
        backAction={currentStep > 0 ? goBackToStep : openPopUpLeave}
        isDisabled={!formik.dirty || !formik.isValid}
      />
      <ModalKYC
        isOpened={isOpened}
        closeModal={closeModal}
        onClose={() => formik.setFieldTouched('activitySector')}
      >
        <ModalRadioGroupContainer>
          <RadioGroup
            id="activitySector-radio-group"
            label={t('basicInformations.sectorList.fieldDescription')}
            title={t('basicInformations.sectorList.title')}
            options={sectors}
            onChange={({ value }) => {
              formik.setFieldValue('activitySector', value);
              closeModal();
            }}
            value={formik.values.activitySector}
            touched={formik.touched.activitySector}
            buttonWidth="100%"
            rowGap="1.6rem"
            wrapOnMobile={false}
          />
        </ModalRadioGroupContainer>
      </ModalKYC>
      <InformationModal
        id="information-modal"
        isOpened={isPopUpEditOpened}
        closeModal={closePopUpEdit}
      >
        <ModalWrapper className="informationFormModalWrapper">
          <ContentWrapper className="informationFormContentWrapper">
            <TitleContent>{t('LeavePageEdit.title')}</TitleContent>
            <DescriptionWrapper style={{ marginBottom: '4.8rem' }}>
              {t('LeavePageEdit.description')}{' '}
            </DescriptionWrapper>
          </ContentWrapper>
          <ButtonModalWrapper className="informationFormButtonModalWrapper">
            <StyledModalValidateButtonWrapper
              onClick={closePopUpEdit}
              type="button"
              isBold={false}
              id="modal-cancel-button"
              level="primary"
            >
              {t('common.cancel')}
            </StyledModalValidateButtonWrapper>
            <StyledModalValidateButtonWrapper
              onClick={handleClick}
              type="button"
              level="ghost"
              buttonType="border"
              id="modal-back-button"
            >
              <ButtonLabel fontSize="1.8rem" action="validate">
                <StyledButtonLoader
                  isLoading={spinnerLoading}
                  isTextInline
                  isHideBackground={false}
                />
                <ValidateLabelWrapper>
                  <SpanLabel>
                    {t('basicInformations.backToWebMarchant')}
                  </SpanLabel>
                </ValidateLabelWrapper>
              </ButtonLabel>
            </StyledModalValidateButtonWrapper>
          </ButtonModalWrapper>
        </ModalWrapper>
      </InformationModal>
      <Modal
        isVisible={isPopUpLeaveOpened}
        isFullScreen={false}
        handleCloseModal={closePopUpLeave}
        isClosable={true}
      >
        <ModalWrapper>
          <SVGIconErrorWrapper>
            <base-icon icon="warning" />
          </SVGIconErrorWrapper>
          <ContentWrapper>
            <TitleWrapper level={3}>{t('LeavePage.title')}</TitleWrapper>
            <DescriptionWrapper>
              <span className="strong">{t('LeavePage.description')}</span>
              <br />
              <span>{t('LeavePage.confirmation')}</span>
            </DescriptionWrapper>
          </ContentWrapper>
          <ButtonModalWrapper>
            <StyledModalValidateButtonWrapper
              id="modal-leave-cancel-button"
              type="button"
              height="3.6rem"
              onClick={closePopUpLeave}
              isBold={false}
              buttonType="border"
            >
              {t('common.cancel')}
            </StyledModalValidateButtonWrapper>
            <StyledModalCancelButtonWrapper
              id="modal-leave-edit-button"
              type="button"
              height="3.6rem"
              onClick={returnToUrlWebMerchant}
              isBold={false}
            >
              <SVGIconReturnWrapper>
                <base-icon icon="arrow-left-circle" />{' '}
              </SVGIconReturnWrapper>
              {t('common.back')}
            </StyledModalCancelButtonWrapper>
          </ButtonModalWrapper>
        </ModalWrapper>
      </Modal>
      {showModalLeave && (
        <ModalLeave
          title={t('LeavePage.title')}
          description={t('LeavePage.description')}
          descriptionProgress={t('LeavePage.descriptionProgress')}
          confirmation={t('LeavePage.confirmation')}
          close={onShowModalLeaveFn}
        />
      )}
      {!hasKycBloc4 && isShowModalBadFormat && (
        <ModalBadFormat
          title={
            <Trans
              i18nKey={t('basicInformations.errors.modalBadFormat.title')}
              values={{ title: capitalize(badFormatData.fields) }}
            />
          }
          description={
            <Trans
              i18nKey="basicInformations.errors.modalBadFormat.message"
              values={{
                inputName: badFormatData.fields,
                badInputs: badFormatData.badInputs,
              }}
            />
          }
        />
      )}
    </>
  );
};

export default InformationFormWestCoast;
