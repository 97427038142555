/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { InfoGlyphIcon, CrossCircleIcon } from '../Icons';

export const InputNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
`;

export const InputLabel = styled.span<{ disabled: boolean }>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.vendor.colors.disabled : theme.vendor.colors.text};
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`;

export const UnstyledInput = styled.input`
  border: none;
  background-image: none;
  background-color: transparent;
  color: var(--neutrals-500);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  &:focus {
    outline: none;
  }
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 95%;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  &::placeholder {
    color: ${({ theme }) => theme.vendor.colors.placeholder};
    font-family: 'Open Sans';
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 2.4rem;
  }
`;

export const InputAndResetContainer = styled.div<{ resetSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  column-gap: 0.8rem;
  & button {
    border: 1px solid
      ${({ theme, resetSelected }) => {
        if (resetSelected) {
          return theme.vendor.colors.primary;
        }
        return theme.vendor.colors.text;
      }};
    color: ${({ theme, resetSelected }) => {
      if (resetSelected) {
        return theme.vendor.colors.primary;
      }
      return theme.vendor.colors.text;
    }};
    background-color: ${({ theme, resetSelected }) => {
      if (resetSelected) {
        return theme.vendor.colors.lightest;
      }
      return '#ffffff';
    }};
  }
`;

export const InputContainer = styled.div<{
  disabled: boolean;
  isError: boolean;
  isValid: boolean;
  isTouched: boolean;
}>`
  border: 1px solid
    ${({ theme, disabled, isError, isValid, isTouched }) => {
      if (disabled) {
        return theme.vendor.colors.disabled;
      }
      if (isError) {
        return theme.vendor.colors.error;
      }
      if (isValid && isTouched) {
        return theme.vendor.colors.primary;
      }
      return theme.vendor.colors.text;
    }};
  border-radius: 4px;
  padding: 0.6rem;
  height: 4.8rem;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.vendor.colors.disabledBackground : '#ffffff'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 1;
  flex-grow: 1;
  cursor: text;
`;

export const AfterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  column-gap: 0.4rem;
`;

export const InfoGlyphIconWrapper = styled(InfoGlyphIcon)`
  height: 2.4rem;
`;

export const CrossCircleWrapper = styled(CrossCircleIcon)`
  height: 2.4rem;
`;

export const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ErrorMessageWrapper = styled.span`
  color: ${({ theme }) => theme.vendor.colors.error};
`;

export const AfterTextWrapper = styled.span`
  width: 6rem;
  text-align: center;
  align-self: center;
`;
