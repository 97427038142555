import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@sweb-front/store';
import { RefusedIconPage } from '@vat/components';
import {
  ContentWrapper,
  SectionWrapper,
  SVGIconWrapper,
  TitleCmp,
  ContentParagraph,
  BoldParagraph,
} from './styles';
import { CHANELTYPE } from '@sweb-front/types';
import useRefusedPayment from './useRefusedPayment';

const RefusedPayment = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const parameters = useAppSelector((state) => state.parameters.state);
  useRefusedPayment(opportunity, parameters);

  const { distributionChannelCD } = opportunity;

  return (
    <SectionWrapper>
      <SVGIconWrapper>
        <RefusedIconPage />
      </SVGIconWrapper>
      <ContentWrapper>
        <TitleCmp>{t('responses.refused.title')}</TitleCmp>
        <ContentParagraph>{t('responses.refused.gratitude')}</ContentParagraph>
        <ContentParagraph>
          {t('responses.refused.insufficientRessources')}
        </ContentParagraph>
        <ContentParagraph>
          {t('responses.refused.informationWay')}
        </ContentParagraph>
        {distributionChannelCD === CHANELTYPE.POS && (
          <BoldParagraph>
            {t('responses.refused.securedBankInformations')}
          </BoldParagraph>
        )}
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default RefusedPayment;
