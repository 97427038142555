import { useRef, useState } from 'react';
import { useBreakpoints } from '@sweb-front/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { CHANELTYPE, IProducts } from '@sweb-front/types';
import { TextWrapper, formatNumberWithComma } from '@vat/containers';
import {
  OrderSummaryCardHeader,
  ProductNameAndPriceContainer,
  CardContent,
  CardDisclosure,
  CardContentLine,
  CardSummaryCardCreditContent,
  StyledImage,
  ProductContainer,
  OrderSummaryCardHeaderProductSummary,
  OrderSummaryCardHeaderFinanceSummary,
  PaddedSpan,
  CardContentLineBold,
  BoldTextAmount,
  TextHeaderAmount,
  NormalText,
  SubTextLabel,
  CardContentLineTAEGCredit,
  CardContentLineTradeIn,
  CardContentLineTradeInIconWrapper,
  CardContentLineTradeInAmount,
  CardContentLineTradeInAmountLight,
  ProductNameTitle,
  MonthlyLabel,
  TranslationWrapper,
  OrderSummaryCheckCircleWrapper,
  OrderSummaryCheckCircleLabel,
  StyledDetailsWrapper,
  StyledDetailsSpan,
} from './styles';
import { CheckCircle } from '../Icons';

export type OrderSummaryCardCreditContentProps = {
  type?: string;
  name?: string;
  logo?: string;
  description?: string;
  total?: number;
  nbOfMonths?: number;
  amountPerMonth?: number;
  totalToPay?: number;
  fixedTAEG?: number;
  interestsRate?: number;
  interests?: number;
  withBorderTop?: boolean;
  isMono?: boolean;
  tradeIn?: IProducts;
  supportAndService?: IProducts[];
  isChildren?: boolean;
  distributionChannelCD?: CHANELTYPE;
};

const OrderSummaryCardCreditContent = ({
  total,
  name,
  description,
  logo,
  nbOfMonths,
  amountPerMonth,
  tradeIn,
  totalToPay,
  interestsRate,
  fixedTAEG,
  isMono,
  interests,
  withBorderTop,
  isChildren,
  distributionChannelCD,
}: OrderSummaryCardCreditContentProps) => {
  const { t } = useTranslation();
  const nodeRef = useRef(null);
  const [isOpened, setIsOpened] = useState(false);
  const { isMobile } = useBreakpoints();
  const isMobileWidth = isMobile ? 48 : 74;
  const isMobileHeight = isMobile ? 44 : 68;
  const PLACEHOLDER = './images/Apple_placeholder.svg';
  const [displayDetails, setDisplayDetails] = useState(false);
  const CSSTransitionTimeout = 225;
  const handleDisplayDetails = (close: boolean) =>
    setTimeout(() => setDisplayDetails(close), CSSTransitionTimeout);
  const [nbOfDetailsClicked, setNbOfDetailsClicked] = useState(0);
  return (
    <CardSummaryCardCreditContent data-testid="financed-element">
      <CardDisclosure
        tabIndex={0}
        key={name}
        withBorderTop={withBorderTop}
        fullWidth={!isChildren}
        onBlur={() => {
          setIsOpened(false);
          handleDisplayDetails(false);
        }}
      >
        <OrderSummaryCardHeader>
          <OrderSummaryCardHeaderProductSummary>
            <ProductContainer>
              <StyledImage
                src={logo || PLACEHOLDER}
                onError={(event) => {
                  if (!isChildren) {
                    (event.target as HTMLImageElement).src =
                      './images/Apple.svg';
                  } else {
                    (event.target as HTMLImageElement).src = PLACEHOLDER;
                  }
                }}
                alt={`${name} logo`}
                width={!isChildren ? 48 : isMobileWidth}
                height={!isChildren ? 44 : isMobileHeight}
              />
              <ProductNameAndPriceContainer>
                {!isChildren && (
                  <ProductNameTitle
                    data-testid="orderSummary-product"
                    className={!isOpened ? 'displayLineClamp' : ''}
                  >
                    {description}
                  </ProductNameTitle>
                )}
                {isChildren && (
                  <NormalText
                    line="3"
                    data-testid="orderSummary-product"
                    className={!isOpened ? 'displayLineClamp' : ''}
                  >
                    {description}
                  </NormalText>
                )}
                <TextHeaderAmount>
                  {formatNumberWithComma(total)} €
                </TextHeaderAmount>
              </ProductNameAndPriceContainer>
            </ProductContainer>
          </OrderSummaryCardHeaderProductSummary>
          <OrderSummaryCardHeaderFinanceSummary>
            <PaddedSpan className="paddedSpan">
              {formatNumberWithComma(amountPerMonth)} €&nbsp;
              {!isChildren && (
                <MonthlyLabel>
                  {t('orderSummary.summary.monthlyCost')}
                </MonthlyLabel>
              )}
              {isChildren && (
                <NormalText className="isChildren">
                  {t('orderSummary.summary.monthlyCost')}
                </NormalText>
              )}
              &nbsp;
              <TranslationWrapper>
                <Trans
                  i18nKey="orderSummary.summary.monthlyDuration"
                  values={{
                    month: nbOfMonths,
                    percentage: formatNumberWithComma(interestsRate),
                  }}
                  components={[<span />, <span />]}
                />
              </TranslationWrapper>
            </PaddedSpan>
          </OrderSummaryCardHeaderFinanceSummary>
          {tradeIn && distributionChannelCD !== CHANELTYPE.POS && (
            <OrderSummaryCheckCircleWrapper>
              <CheckCircle
                className="checkCircleOrderSummaryCardCreditContent"
                fill="#268038"
              />
              <OrderSummaryCheckCircleLabel>
                {t('orderSummary.summary.tradeIn')}
              </OrderSummaryCheckCircleLabel>
            </OrderSummaryCheckCircleWrapper>
          )}
          {!isOpened && !displayDetails && (
            <StyledDetailsWrapper
              nbOfDetailsClicked={nbOfDetailsClicked}
              onClick={() => {
                setIsOpened(true);
                handleDisplayDetails(true);
                setNbOfDetailsClicked(() => nbOfDetailsClicked + 1);
              }}
            >
              <StyledDetailsSpan>
                {t('orderSummary.details.informationDetailsOpened')}
              </StyledDetailsSpan>
            </StyledDetailsWrapper>
          )}
        </OrderSummaryCardHeader>
      </CardDisclosure>
      <CSSTransition
        in={isOpened}
        nodeRef={nodeRef}
        timeout={CSSTransitionTimeout}
        unmountOnExit
        classNames="animation"
      >
        <CardContent ref={nodeRef}>
          <CardContentLine>
            {tradeIn !== undefined && Object.keys(tradeIn).length !== 0 ? (
              <>
                <CardContentLineTradeIn>
                  <span>{t('orderSummary.details.originalPrice')}</span>
                  <CardContentLineTradeInIconWrapper>
                    <span>{t('orderSummary.details.tradeInAmount')}</span>
                  </CardContentLineTradeInIconWrapper>
                  <span>{t('orderSummary.details.financedAmountTradeIn')}</span>
                </CardContentLineTradeIn>
                <CardContentLineTradeIn>
                  <CardContentLineTradeInAmount>
                    {tradeIn.total + total} €
                  </CardContentLineTradeInAmount>
                  <CardContentLineTradeInAmountLight>
                    - {tradeIn.total} €
                  </CardContentLineTradeInAmountLight>
                  <CardContentLineTradeInAmount>
                    {total} €
                  </CardContentLineTradeInAmount>
                </CardContentLineTradeIn>
              </>
            ) : (
              <>
                <span>{t('orderSummary.details.financedAmount')}</span>
                <span>{formatNumberWithComma(total)} €</span>
              </>
            )}
          </CardContentLine>
          <CardContentLine>
            <span>{t('orderSummary.details.duration')}</span>
            <span>{nbOfMonths} mois</span>
          </CardContentLine>
          <CardContentLine>
            <span>{t('orderSummary.details.interestRate')}</span>
            <span>{formatNumberWithComma(interestsRate)} %</span>
          </CardContentLine>
          {isMono && (
            <CardContentLineTAEGCredit>
              <TextWrapper>
                <BoldTextAmount>
                  {t('orderSummary.global.fixedTAEG')}
                </BoldTextAmount>
                <BoldTextAmount>
                  {formatNumberWithComma(fixedTAEG) === 0
                    ? '0,00'
                    : formatNumberWithComma(fixedTAEG)}{' '}
                  %
                </BoldTextAmount>
              </TextWrapper>
              <SubTextLabel>
                {t('orderSummary.global.fixedTAEGLabel')}
              </SubTextLabel>
            </CardContentLineTAEGCredit>
          )}
          <CardContentLine>
            <span>{t('orderSummary.details.interests')}</span>
            <span>{formatNumberWithComma(interests)} €</span>
          </CardContentLine>
          <CardContentLineBold withBorderTop={true}>
            <BoldTextAmount>
              {t('orderSummary.details.monthlyCost')}
            </BoldTextAmount>
            <BoldTextAmount>
              {formatNumberWithComma(amountPerMonth)} €
            </BoldTextAmount>
          </CardContentLineBold>
          <CardContentLineBold withBorderTop={false}>
            <BoldTextAmount>{t('orderSummary.details.total')}</BoldTextAmount>
            <BoldTextAmount>
              {formatNumberWithComma(totalToPay)} €
            </BoldTextAmount>
          </CardContentLineBold>
          <StyledDetailsWrapper nbOfDetailsClicked={nbOfDetailsClicked}>
            <StyledDetailsSpan>
              {t('orderSummary.details.informationDetailsClosed')}
            </StyledDetailsSpan>
          </StyledDetailsWrapper>
        </CardContent>
      </CSSTransition>
    </CardSummaryCardCreditContent>
  );
};

export default OrderSummaryCardCreditContent;
