import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Theme } from '@sweb-front/styles';
import { StyledBackArrow, StyledBackButton, CenterContainer } from './Style';

export const BackButton = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const onClick = () => {
    const cookies = document.getElementById('ot-sdk-cookie-policy');
    if (cookies) {
      cookies.style.display = 'none';
    }
    history(-1);
  };
  const theme = useTheme() as Theme;

  return (
    <StyledBackButton onClick={onClick} type="button">
      <CenterContainer>
        <StyledBackArrow fill={theme.vendor.colors.accent} />
      </CenterContainer>
      <CenterContainer>
        <span>{t('common.back')}</span>
      </CenterContainer>
    </StyledBackButton>
  );
};

export default BackButton;
