import {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  FocusEvent,
  useState,
  useRef,
} from 'react';
import { useTheme } from 'styled-components';
import { Theme } from '@sweb-front/styles';
import {
  CrossCircleWrapper,
  IconWrapper,
  InfoGlyphIconWrapper,
  InputContainer,
  InputLabel,
  InputTextWrapper,
  UnstyledInput,
  ErrorMessageWrapper,
  CrossWrapper,
  OptionalInputText,
} from './styles';
import { CrossIcon } from '../Icons';
import { useTranslation } from 'react-i18next';

export type InputTextProps = {
  id: string;
  type?: 'text' | 'email';
  label?: string;
  value?: string;
  optional?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  isReadOnly?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  onChangeEvent?: ChangeEventHandler<HTMLInputElement>;
  onInput?: (value: string) => void;
  errorMessage?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  touched?: boolean;
  maxLength?: number;
  inputMode?:
    | 'text'
    | 'email'
    | 'none'
    | 'search'
    | 'tel'
    | 'url'
    | 'numeric'
    | 'decimal';
};

const InputText = ({
  id,
  type = 'text',
  label,
  optional,
  value,
  autoComplete,
  disabled = false,
  isReadOnly = false,
  placeholder,
  onChange: onChangeProp,
  onChangeEvent,
  onInput: onInputProp,
  errorMessage,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  touched = false,
  maxLength,
  inputMode = 'text',
}: InputTextProps) => {
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const [isDirty, setIsDirty] = useState(
    touched || (value !== undefined && value !== '')
  );
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onInputContainerClick = () => {
    if (inputRef.current?.value === '') {
      inputRef.current.focus();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      maxLength &&
      e.target.value.length >= maxLength &&
      e.target.value.length >= (value?.length || 0)
    ) {
      return;
    }
    if (onChangeEvent) {
      onChangeEvent(e);
    }
    if (onChangeProp) {
      onChangeProp(e.target?.value);
    }
    if (e.target.value.length > 0) {
      setIsDirty(true);
    }
  };

  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    if (onInputProp) {
      onInputProp(value);
    }
  };

  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocusProp) {
      onFocusProp(e);
    }
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      if (onBlurProp) {
        onBlurProp(e);
      }
      setIsDirty(true);
      setIsFocused(false);
    }, 500);
  };

  const onResetClick = () => {
    if (onChangeProp) {
      onChangeProp('');
    }
  };

  const isError = errorMessage !== undefined && isDirty && touched;
  const isValid = errorMessage === undefined && isDirty;

  return (
    <InputTextWrapper>
      <InputLabel isReadOnly={isReadOnly}>
        {label}
        {optional && (
          <OptionalInputText>{t('common.optional')}</OptionalInputText>
        )}
      </InputLabel>
      <InputContainer
        className={id}
        isReadOnly={isReadOnly}
        disabled={disabled}
        isError={isError}
        isValid={isValid}
        isTouched={touched}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onInputContainerClick}
      >
        <UnstyledInput
          id={id}
          name={id}
          type={type}
          inputMode={inputMode}
          value={value}
          autoComplete={autoComplete}
          disabled={isReadOnly || disabled}
          onChange={onChange}
          onInput={onInput}
          placeholder={placeholder}
          ref={inputRef}
        />
        {!disabled && (
          <IconWrapper>
            {(() => {
              if (isReadOnly) {
                return <></>;
              }
              if (isFocused) {
                return (
                  <CrossWrapper
                    tabIndex={-1}
                    type="button"
                    onClick={() => onResetClick()}
                  >
                    <CrossIcon fill={theme.vendor.colors.border} />
                  </CrossWrapper>
                );
              }
              if (isDirty) {
                if (errorMessage) {
                  return (
                    <InfoGlyphIconWrapper fill={theme.vendor.colors.error} />
                  );
                }
                if (touched) {
                  return (
                    <CrossCircleWrapper fill={theme.vendor.colors.primary} />
                  );
                }
              }
              return <></>;
            })()}
          </IconWrapper>
        )}
      </InputContainer>
      {isError && !disabled && (
        <ErrorMessageWrapper id="errorMessage">
          {errorMessage}
        </ErrorMessageWrapper>
      )}
    </InputTextWrapper>
  );
};

export default InputText;
