import styled from 'styled-components';

const getColorAndBackgroundColor = (theme, isDisabled, backgroundColor) => {
  if (isDisabled) {
    return theme.vendor.colors.disabled;
  }
  return backgroundColor ?? theme.vendor.colors.primary;
};

const getMarginValue = (id: string, isMobile: boolean) => {
  if (
    id === 'contract-download-button-waiting-response' ||
    id === 'contract-download-button-approved-payment'
  ) {
    return isMobile ? 'initial' : 'auto';
  }
  return '';
};

const getBackGroundHoverValue = (
  inactive: boolean,
  level: string,
  isDisabled: boolean,
  backgroundColor: string
) => {
  if (!inactive && !isDisabled) {
    return level === 'primary'
      ? 'var(--main-green-600)'
      : backgroundColor === '#292C2E'
      ? ''
      : 'var(--main-green-50)';
  }
  return '';
};

const getColorHoverValue = (
  inactive: boolean,
  level: string,
  isDisabled: boolean,
  backgroundColor: string
) => {
  return !inactive && !isDisabled && level === 'ghost'
    ? backgroundColor === '#292C2E'
      ? '#181A1B'
      : 'var(--main-green-600)'
    : '';
};

const getColorActiveValue = (
  inactive: boolean,
  level: string,
  isDisabled: boolean,
  backgroundColor: string
) => {
  return !inactive && !isDisabled && level === 'ghost'
    ? backgroundColor === '#292C2E'
      ? '#080809'
      : 'var(--main-green-700)'
    : '';
};

const getBackGroundActiveValue = (
  inactive: boolean,
  level: string,
  isDisabled: boolean
) => {
  return !inactive && !isDisabled && level === 'primary'
    ? 'var(--main-green-700)'
    : '';
};

const getBackGroundFocusValue = (level: string) => {
  return level === 'ghost' ? 'transparent' : '';
};

const getOutlineFocusValue = (
  inactive: boolean,
  level: string,
  isDisabled: boolean
) => {
  return !inactive && !isDisabled && (level === 'primary' || level === 'ghost')
    ? '2px solid var(--dark-green-400)'
    : '';
};

const getOutlineOffsetFocusValue = (
  inactive: boolean,
  level: string,
  isDisabled: boolean
) => {
  return !inactive && !isDisabled && (level === 'primary' || level === 'ghost')
    ? '2px'
    : '';
};

const getWidthValue = (width, isMobile) => {
  return width ? width : isMobile ? '100%' : 'auto';
};

export const StyledButton = styled.button<{
  isDisabled: boolean;
  isBold?: boolean;
  isRounded?: boolean;
  buttonType?: 'filled' | 'border' | 'none';
  height?: string;
  width?: string;
  backgroundColor?: string;
  level?: string;
  isMobile?: boolean;
  inactive?: boolean;
}>`
  font-family: 'Open Sans';
  ${({ isBold }) => {
    if (isBold) {
      return `
        font-family: 'Open Sans Bold';
        letter-spacing: 0;
      `;
    }
    return `
      padding: 0.8rem 1.2rem;
    `;
  }}
  box-shadow: ${({ level }) =>
    level === 'primary' ? ' 0px 4px 4px 0px rgba(0, 0, 0, 0.15)' : ''};
  height: 4.8rem;
  text-align: center;
  padding: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: center;
  gap: ${({ level }) => (level === 'primary' ? '1rem' : '0.8rem')};
  align-self: stretch;
  border-radius: 4px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  ${({ theme, isDisabled, buttonType, backgroundColor }) => {
    switch (buttonType) {
      case 'filled':
        return `
                color: #FFFFFF;
                border: 1px solid  ${getColorAndBackgroundColor(
                  theme,
                  isDisabled,
                  backgroundColor
                )};
                background-color: ${getColorAndBackgroundColor(
                  theme,
                  isDisabled,
                  backgroundColor
                )};
              `;
      case 'border':
        return `
                color: ${
                  isDisabled
                    ? theme.vendor.colors.disabled
                    : backgroundColor ?? theme.vendor.colors.primary
                };
                background-color: #FFFFFF;
                border: 1px solid;
                border-color: ${
                  isDisabled
                    ? theme.vendor.colors.disabled
                    : backgroundColor === '#292C2E'
                    ? '#181A1B'
                    : theme.vendor.colors.primary
                };
              `;
      case 'none':
        return `
                background-color: transparent;
                color: ${
                  isDisabled
                    ? theme.vendor.colors.disabled
                    : theme.vendor.colors.primary
                };
                border: 0;
              `;
      default:
        return ``;
    }
  }};
  font-size: 1.6rem;
  line-height: 2.4rem;
  width: ${({ width, isMobile }) => getWidthValue(width, isMobile)};
  &#nav-back-button div:nth-child(1) {
    margin-right: 8px;
  }
  &:hover {
    background-color: ${({ inactive, level, isDisabled, backgroundColor }) =>
      getBackGroundHoverValue(inactive, level, isDisabled, backgroundColor)};
    color: ${({ inactive, level, isDisabled, backgroundColor }) =>
      getColorHoverValue(inactive, level, isDisabled, backgroundColor)};
    svg path {
      fill: ${({ inactive, level, isDisabled, backgroundColor }) =>
        getColorHoverValue(inactive, level, isDisabled, backgroundColor)};
    }
    border-color: ${({ inactive, level, isDisabled, backgroundColor }) =>
      getColorHoverValue(inactive, level, isDisabled, backgroundColor)};
  }
  &:active {
    background-color: ${({ inactive, level, isDisabled }) =>
      getBackGroundActiveValue(inactive, level, isDisabled)};
    color: ${({ inactive, level, isDisabled, backgroundColor }) =>
      getColorActiveValue(inactive, level, isDisabled, backgroundColor)};
    svg path {
      fill: ${({ inactive, level, isDisabled, backgroundColor }) =>
        getColorActiveValue(inactive, level, isDisabled, backgroundColor)};
    }
    border-color: ${({ inactive, level, isDisabled, backgroundColor }) =>
      getColorActiveValue(inactive, level, isDisabled, backgroundColor)};
  }
  &:focus {
    background-color: ${({ level }) => getBackGroundFocusValue(level)};
    outline: ${({ inactive, level, isDisabled }) =>
      getOutlineFocusValue(inactive, level, isDisabled)};
    outline-offset: ${({ inactive, level, isDisabled }) =>
      getOutlineOffsetFocusValue(inactive, level, isDisabled)};
  }
  margin: ${({ id, isMobile }) => getMarginValue(id, isMobile)};
  &#recomputedModalButton {
    background-color: #e5f5fd;
    width: auto;
  }
`;

export const StyledButtonContainer = styled.div`
  height: 100%;
  display: flex;
  font-family: 'Open Sans Bold';
  flex-direction: column;
  justify-content: center;
  justify-items: center;
`;
