import { Icons, Prompt } from '@sweb-front/components';
import * as messages from './messages';

export interface GenericErrorProps {
  redirectToPopHome: () => void;
  requestId?: string;
}

const GenericError = ({
  redirectToPopHome,
  requestId,
}: GenericErrorProps): React.ReactElement => {
  const usedRequestId = requestId ?? 'unknown';
  return (
    <Prompt
      icon={<Icons.CloudIcon />}
      title={messages.TITLE}
      text={
        <>
          {messages.REFUSE_CONTACT}
          {usedRequestId && (
            <>
              <br />
              {messages.REQUESTID_HANDLER}
              {usedRequestId}
            </>
          )}
        </>
      }
      tertiaryButtonLabel={messages.BACK_BUTTON}
      onTertiaryButtonClick={redirectToPopHome}
    />
  );
};

export default GenericError;
