import {
  useBreakpoints,
  useLoadingHook,
  useManagingStep,
} from '@sweb-front/hooks';
import { useFormik } from 'formik';
import { findPriOverdraftAmount, trackEvent } from '@sweb-front/utils';
import {
  IOpportunityState,
  setIncomes,
  setOutgoings,
  setProfession,
  updateParcoursNavigation,
  useAppDispatch,
} from '@sweb-front/store';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { FocusEventHandler, useContext, useEffect, useState } from 'react';
import { ErrorContext, computeInitialTouched } from '@vat/utils';
import { postData, setIdHashed } from '../utils';
import { SECTORCODES, SECTORLIST, SOLVA, SOLVAPAGE } from '@vat/configuration';

const MIN_INCOME_OUTCOME = 0;
const MAX_INCOME_OUTCOME = 99999;

export type UseIncomeExpensesFormOptions = {
  opportunity: IOpportunityState;
  codeParameters: string;
  fields: string[];
};

export const useIncomeExpensesForm = ({
  opportunity,
  codeParameters,
  fields,
}: UseIncomeExpensesFormOptions) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isDesktop } = useBreakpoints();
  const { goBackToStep, goToNextStep } = useManagingStep();
  const sectors = SECTORLIST.map((sector) => ({
    value: SECTORCODES[sector],
    label: t(`incomesAndExpenses.form.sectorList.${sector}`),
  }));
  const { person } = opportunity;
  const salary = person?.incomes?.find(
    (income) => income.incomeTypeCd === 'SAL'
  );
  const outgoings = person?.outgoings?.find(
    (outgoing) => outgoing.outgoingTypeCd === 'AGR'
  );
  const updateError = useContext(ErrorContext);
  const [showBubbleInfo, setShowBubbleInfo] = useState(false);
  const [showBaseMonthIncome, setShowBaseMonthIncome] = useState(
    salary?.incomeAmt && salary.incomeAmt !== undefined && salary.incomeAmt > 0
  );
  const [showExpensesBubbleInfo, setShowExpensesBubbleInfo] = useState(false);
  const { isActionLoading, updateIsActionLoading } = useLoadingHook();

  const schema = z.object({
    activitySector: z
      .string({
        required_error: t('incomesAndExpenses.form.errors.activitySectors'),
        invalid_type_error: t('incomesAndExpenses.form.errors.activitySectors'),
      })
      .optional()
      .superRefine((value, ctx) => {
        if (fields.includes(SOLVAPAGE.SOLVACT) && value === undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('incomesAndExpenses.form.errors.activitySectors'),
          });
        }
      }),
    monthlyIncome: z.coerce
      .number({
        required_error: t(
          'incomesAndExpenses.form.errors.monthlyIncomeAndExpenses.required'
        ),
        invalid_type_error: t(
          'incomesAndExpenses.form.errors.monthlyIncomeAndExpenses.format'
        ),
      })
      .min(
        MIN_INCOME_OUTCOME,
        t('incomesAndExpenses.form.errors.monthlyIncomeAndExpenses.format')
      )
      .max(
        MAX_INCOME_OUTCOME,
        t('incomesAndExpenses.form.errors.monthlyIncomeAndExpenses.format')
      ),
    monthNumberBaseCalculation: z.coerce
      .number()
      .optional()
      .superRefine((value, ctx) => {
        if (Number(formik.values.monthlyIncome) === 0) {
          return;
        }
        if (
          formik.values.monthlyIncome &&
          Number(formik.values.monthlyIncome) > 0 &&
          value &&
          value >= 12 &&
          value <= 16
        ) {
          return;
        }
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t(
            'incomesAndExpenses.form.errors.monthNumberBaseCalculation'
          ),
        });
      }),
    monthlyExpenses: z.coerce
      .number({
        required_error: t(
          'incomesAndExpenses.form.errors.monthlyExpenses.required'
        ),
        invalid_type_error: t(
          'incomesAndExpenses.form.errors.monthlyExpenses.format'
        ),
      })
      .min(
        MIN_INCOME_OUTCOME,
        t('incomesAndExpenses.form.errors.monthlyIncomeAndExpenses.format')
      )
      .max(
        MAX_INCOME_OUTCOME,
        t('incomesAndExpenses.form.errors.monthlyIncomeAndExpenses.format')
      ),
  });
  type IncomeExpensesFormSchema = z.infer<typeof schema>;

  const initialValues = {
    activitySector: person?.profession?.ihmSector,
    monthlyIncome:
      typeof salary?.incomeAmt === 'number' ? salary?.incomeAmt : undefined,
    monthNumberBaseCalculation: salary?.incomeNb,
    monthlyExpenses:
      typeof outgoings?.outgoingAmt === 'number'
        ? outgoings?.outgoingAmt
        : undefined,
  };

  const validate = (values: unknown) => {
    const result = schema.safeParse(values);
    if (result.success === true) {
      return;
    }
    const errors = result.error.errors.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.path[0]]: curr.message,
      };
    }, {});
    return errors;
  };

  const onSubmit = (values: IncomeExpensesFormSchema) => {
    if (isActionLoading) {
      return;
    }
    const professionObject = values.activitySector
      ? {
          ...person.profession,
          ihmSector: values.activitySector,
        }
      : null;
    const incomes = [
      {
        incomeAmt: Number(values.monthlyIncome) || 0,
        incomeTypeCd: 'SAL',
        incomeNb: values.monthNumberBaseCalculation,
      },
    ];
    const outgoings = [
      {
        outgoingAmt: Number(values.monthlyExpenses) || 0,
        outgoingTypeCd: 'AGR',
      },
    ];
    // UPDATE ON REDUX STORE ONLY CHANGED PERSON DATA
    dispatch(setIncomes(incomes as never));
    dispatch(setOutgoings(outgoings as never));
    dispatch(setProfession(professionObject));
    updateIsActionLoading(true);
    postData(
      'vendors/opportunities/v1/opportunity',
      {
        persons: [
          {
            personId: person.personId,
            incomes,
            outgoings,
            profession: professionObject,
          },
        ],
      },
      () => {
        dispatch(
          updateParcoursNavigation({
            name: SOLVA,
            loaded: true,
            actionPageDone: true,
            disabled: false,
          })
        );
        updateIsActionLoading(false);
        goToNextStep();
      },
      () => {
        dispatch(
          updateParcoursNavigation({
            name: SOLVA,
            loaded: true,
            actionPageDone: true,
            disabled: false,
            params: {
              hasError: true,
            },
          })
        );
        updateIsActionLoading(false);
        updateError();
      }
    );
  };

  const initialTouched = computeInitialTouched(initialValues);

  const formik = useFormik<Partial<IncomeExpensesFormSchema>>({
    initialValues,
    initialTouched,
    validateOnChange: true,
    validateOnBlur: true,
    validate,
    onSubmit,
  });

  const onIncomeFocus = () => {
    setShowBaseMonthIncome(
      formik.values.monthlyIncome !== undefined &&
        Number(formik.values.monthlyIncome) > 0
    );
    setShowBubbleInfo(true);
  };

  const onIncomeBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    // HACK, TODO: useTransition une fois passé en react 18
    setTimeout(() => {
      setShowBubbleInfo(false);
    }, 250);
    setShowBaseMonthIncome(
      formik.values.monthlyIncome !== undefined &&
        Number(formik.values.monthlyIncome) > 0
    );
    formik.handleBlur(e);
  };

  const onIncomeChange = (value: string | undefined) => {
    formik.setFieldValue('monthlyIncome', value);
    setShowBaseMonthIncome(
      formik.values.monthlyIncome !== undefined &&
        Number(formik.values.monthlyIncome) > 0
    );
  };

  const onExpensesFocus = () => {
    setShowExpensesBubbleInfo(true);
  };

  const onExpensesBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setShowExpensesBubbleInfo(false);
    formik.handleBlur(e);
  };

  useEffect(() => {
    /* eslint no-underscore-dangle: 0 */
    if (opportunity?.opportunityIdExt) {
      const isMono: boolean = opportunity?.bagType === 'MONO';
      const { financialDetails } = opportunity?.offers?.[0]?.loans?.[0] ?? {};
      trackEvent({
        event: 'module_interaction',
        site: 'Ecommerce',
        workflow: codeParameters,
        pageName: 'E-Commerce : Formulaire Solva',
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        Amount: (isMono
          ? financialDetails?.overdraftAmt
          : findPriOverdraftAmount(opportunity.offers[0].loans)
        )?.toString(),
        Rate: isMono ? financialDetails?.tncRt?.toString() : undefined,
        Term: isMono ? financialDetails?.term?.toString() : undefined,
        MonthlyPayment: isMono
          ? financialDetails?.monthlyPaymentWithoutInsuranceAmt?.toString()
          : undefined,
        opportunityIdHashed: setIdHashed(opportunity),
        ContributorCode: opportunity.distributor.distributorNb,
        pathType: isMono ? 'mono panier' : 'multi panier',
      });
    }
  }, []);

  const isDirty =
    formik.values.activitySector !== undefined &&
    formik.values.monthlyIncome !== undefined &&
    (Number(formik.values.monthlyIncome) > 0
      ? formik.values.monthNumberBaseCalculation !== undefined
      : true) &&
    formik.values.monthlyExpenses !== undefined;

  return {
    formik,
    t,
    isDesktop,
    isDirty,
    goBackToStep,
    sectors,
    showBubbleInfo,
    showBaseMonthIncome,
    showExpensesBubbleInfo,
    onIncomeFocus,
    onIncomeBlur,
    onExpensesFocus,
    onExpensesBlur,
    onIncomeChange,
    isActionLoading,
  };
};
