import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { TextWrapper, formatNumberWithComma } from '@vat/containers';
import { getUniqueId } from '@vat/utils';
import {
  BoldTextAmount,
  BolderTextAmount,
  CardContentGlobal,
  CardContentLineGlobal,
  CardContentLineGlobalTAEG,
  CardDisclosure,
  CardSummaryCardCreditContent,
  CenterCol,
  MediumSpan,
  OrderSummaryCardGlobal,
  OrderSummaryCardHeaderProductSummary,
  OrderSummaryGlobalCardTitle,
  OrderSummaryProductSummaryDescription,
  PaddedSpan,
  SmallTextPill,
  StyledLink,
  SubTextLabel,
  PeriodLineGlobal,
  MonthlyLabel,
  StyledDetailsWrapper,
  StyledDetailsSpan,
} from './styles';
import { Pill } from '../Pill/Pill';

export type OrderSummaryCardGlobalContentProps = {
  periods?: {
    startMonth?: string;
    endMonth?: string;
    amountPerMonth?: number;
  }[];
  total?: number;
  borrowedAmount?: number;
  fixedTAEG?: number;
  interests?: number;
  withBorderTop?: boolean;
  openModal?: () => void;
};

const OrderSummaryCardGlobalContent = ({
  periods,
  total,
  borrowedAmount,
  fixedTAEG,
  interests,
  openModal,
}: OrderSummaryCardGlobalContentProps) => {
  const { t } = useTranslation();
  const nodeRef = useRef(null);
  const [isOpened, setIsOpened] = useState(false);
  const [displayDetails, setDisplayDetails] = useState(false);
  const CSSTransitionTimeout = 225;
  const handleDisplayDetails = (close: boolean) =>
    setTimeout(() => setDisplayDetails(close), CSSTransitionTimeout);
  const [nbOfDetailsClicked, setNbOfDetailsClicked] = useState(0);
  return (
    <>
      <CardSummaryCardCreditContent data-testid="orderSummary-global">
        <CardDisclosure fullWidth>
          <OrderSummaryCardGlobal>
            <OrderSummaryCardHeaderProductSummary>
              <OrderSummaryGlobalCardTitle>
                {t('orderSummary.global.title')}
              </OrderSummaryGlobalCardTitle>
            </OrderSummaryCardHeaderProductSummary>
            <OrderSummaryProductSummaryDescription>
              {t('orderSummary.global.description')}
            </OrderSummaryProductSummaryDescription>
            <StyledLink onClick={openModal} id="open-recap-modal">
              {t('orderSummary.global.understandSchedule')}
            </StyledLink>
            <PeriodLineGlobal>
              {periods?.map((period) => (
                <CardContentLineGlobal key={getUniqueId()}>
                  <Pill>
                    <CenterCol>
                      <SmallTextPill>
                        {period.startMonth === '0'
                          ? `Les ${period.endMonth} premiers mois`
                          : `Du ${period.startMonth} au ${period.endMonth}ème mois`}
                      </SmallTextPill>
                    </CenterCol>
                  </Pill>
                  <PaddedSpan className="summaryPaddedSpan">
                    <BoldTextAmount>
                      {formatNumberWithComma(period.amountPerMonth)} €
                    </BoldTextAmount>
                    &nbsp;
                    <MonthlyLabel>
                      {t('orderSummary.summary.monthlyCost')}
                    </MonthlyLabel>
                  </PaddedSpan>
                </CardContentLineGlobal>
              ))}
            </PeriodLineGlobal>
            <CardContentLineGlobal name={'totalAmount'}>
              <MediumSpan>{t('orderSummary.global.total')}</MediumSpan>
              <BolderTextAmount className="summaryBolderTextAmount">
                {formatNumberWithComma(total)} €
              </BolderTextAmount>
            </CardContentLineGlobal>
            {!isOpened && !displayDetails && (
              <StyledDetailsWrapper
                nbOfDetailsClicked={nbOfDetailsClicked}
                onClick={() => {
                  setIsOpened(true);
                  handleDisplayDetails(true);
                  setNbOfDetailsClicked(() => nbOfDetailsClicked + 1);
                }}
              >
                <StyledDetailsSpan>
                  {t('orderSummary.details.informationDetailsOpened')}
                </StyledDetailsSpan>
              </StyledDetailsWrapper>
            )}
          </OrderSummaryCardGlobal>
          <CSSTransition
            in={isOpened}
            nodeRef={nodeRef}
            timeout={CSSTransitionTimeout}
            unmountOnExit
            classNames="animation"
          >
            <CardContentGlobal ref={nodeRef}>
              <CardContentLineGlobal>
                <span>{t('orderSummary.global.borrowedAmount')}</span>
                <span>{formatNumberWithComma(borrowedAmount)} €</span>
              </CardContentLineGlobal>
              <CardContentLineGlobalTAEG>
                <TextWrapper>
                  <BoldTextAmount className="boldTextAmountFixedTaeg">
                    {t('orderSummary.global.fixedTAEG')}
                  </BoldTextAmount>
                  <BoldTextAmount className="boldTextAmountFixedTaeg">
                    {formatNumberWithComma(fixedTAEG) === 0
                      ? '0,00'
                      : formatNumberWithComma(fixedTAEG)}{' '}
                    %
                  </BoldTextAmount>
                </TextWrapper>
                <SubTextLabel>
                  {t('orderSummary.global.fixedTAEGLabel')}
                </SubTextLabel>
              </CardContentLineGlobalTAEG>
              <CardContentLineGlobal>
                <span>{t('orderSummary.global.interests')}</span>
                <span>{formatNumberWithComma(interests)} €</span>
              </CardContentLineGlobal>
              <StyledDetailsWrapper
                nbOfDetailsClicked={nbOfDetailsClicked}
                onClick={() => {
                  setIsOpened(false);
                  handleDisplayDetails(false);
                }}
              >
                <StyledDetailsSpan>
                  {t('orderSummary.details.informationDetailsClosed')}
                </StyledDetailsSpan>
              </StyledDetailsWrapper>
            </CardContentGlobal>
          </CSSTransition>
        </CardDisclosure>
      </CardSummaryCardCreditContent>
    </>
  );
};

export default OrderSummaryCardGlobalContent;
