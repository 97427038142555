import styled from 'styled-components';
import { IBubbleInfoProps } from './BubbleInfo';

export const BubbleInfoWrapper = styled.div`
  color: var(--neutrals-700);
  background-color: #e5f5fd;
  padding: 1.2rem 2rem;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
  border-radius: 0.4rem;
  display: flex;
  gap: 1.2rem;
  justify-content: left;
  vertical-align: middle;
  position: relative;

  .bubble-content {
    flex: 1;
    align-self: center;
  }
  &#bubble-info-approved-payment {
    margin-top: 1.6rem;
  }
`;

export const BubbleInfoTitleWrapper = styled.div`
  margin: 0;
  line-height: 2rem;
  font-size: 1.45rem;
  span.light-title {
    font-family: 'Open Sans';
  }
  span.strong-title {
    font-family: 'Open Sans bold';
  }
`;

export const BubbleInfoListWrapper = styled.ul<{
  color?: string;
  marker: string;
  isCustomedMarker: boolean;
}>`
  padding: 0;
  margin: 0;
  list-style-type: ${(props) => {
    if (!props.marker || props?.marker === '') {
      return 'none';
    }
    if (props?.isCustomedMarker) {
      return `'${props.marker}'`;
    }
    return props.marker;
  }};
  li {
    color: ${(props) => {
      return props.color ?? 'var(--neutrals-700)';
    }};
    line-height: 2rem;
    margin-left: ${(props: IBubbleInfoProps) => {
      if (!props.marker || props?.marker?.trim() === '') {
        return 0;
      }
      return props.isCustomedMarker ? '0.4rem' : '1.4rem';
    }};
    &::before {
      content: '';
      padding-left: ${(props: IBubbleInfoProps) => {
        if (!props.marker || props?.marker?.trim() === '') {
          return 0;
        }
        return props.isCustomedMarker ? '0.6rem' : '0';
      }};
    }
  }
`;

export const ImgWrapper = styled.div`
  padding: 0;
  & base-icon {
    width: 2.4rem;
    height: 2.4rem;
    --icon-width: 2rem;
    --icon-height: 2rem;
    --color-icon: #007590;
  }
`;

export const Chevron = styled.div`
  margin-top: 0.4rem;
  width: 0;
  height: 0;
  border-left: 0.9rem solid transparent;
  border-right: 0.9rem solid transparent;
  border-bottom: 0.9rem solid #e5f5fd;
  margin-left: 5%;
`;

export default BubbleInfoWrapper;
