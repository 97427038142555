import { CONTEXT_ECONTRAT_PROVISOIRE } from '@sweb-front/services';
import { IRoutingContext, IFinancialDetails } from '@sweb-front/types';
import {
  findPriOverdraftAmount,
  trackEvent,
  trackCustomAction,
  trackLeavingPage,
} from '@sweb-front/utils';
import { setIdHashed } from '../../containers/forms/utils';
import { useCallback, useContext, useEffect } from 'react';
import { PageLoadingContext } from '@vat/utils';

const useApprovedPayment = (opportunity, parameters, navigation) => {
  const { routingContexts } = navigation;
  const updatePageIsLoading = useContext(PageLoadingContext);

  const onContractClick = useCallback(() => {
    if (Array.isArray(routingContexts)) {
      const linkContract: IRoutingContext | undefined = routingContexts?.find(
        (routingContext: IRoutingContext) =>
          routingContext.contextUse === CONTEXT_ECONTRAT_PROVISOIRE
      );
      if (linkContract) {
        trackCustomAction('le client a cliqué sur le bouton espace contrat');
        window.open(linkContract.url, '_blank');
      }
    }
  }, [routingContexts]);

  useEffect(() => {
    updatePageIsLoading(false);
    trackLeavingPage(() => trackCustomAction('Déchargement de la Page'));
    let financialDetails = {} as IFinancialDetails;

    if (opportunity && opportunity.offers?.length > 0) {
      financialDetails = opportunity.offers[0].loans[0].financialDetails;
    }

    const isMono: boolean = opportunity?.bagType === 'MONO';
    trackEvent({
      event: 'module_interaction',
      pageName: 'E-Commerce : FV',
      site: 'Ecommerce',
      workflow: parameters?.wayCd,
      // eslint-disable-next-line no-underscore-dangle
      environment: (window as unknown as WindowWithEnv)._env_?.env,
      visitorStatus: 'non-logged',
      Amount: (isMono
        ? financialDetails?.overdraftAmt
        : findPriOverdraftAmount(opportunity?.offers?.[0]?.loans)
      )?.toString(),
      Rate: isMono ? financialDetails?.tncRt?.toString() : undefined,
      Term: isMono ? financialDetails?.term?.toString() : undefined,
      MonthlyPayment: isMono
        ? financialDetails?.monthlyPaymentWithoutInsuranceAmt?.toString()
        : undefined,
      opportunityIdHashed: opportunity?.person?.personalInformation
        ? setIdHashed(opportunity)
        : undefined,
      ContributorCode: opportunity?.distributor?.distributorNb ?? '',
      pathType: isMono ? 'mono panier' : 'multi panier',
    });
  }, []);

  return {
    onContractClick,
  };
};

export default useApprovedPayment;
