import { RadioButton } from '@sweb-front/components';
import { CardWithRadioHeader, DetailWrapper, SVGIconWrapper } from './style';

interface ICardProps {
  onSelect: (id: string) => void;
  isSelected: boolean;
  openModal?: () => void;
}

const AutomaticAnalysysMethodCard = (props: ICardProps) => {
  return (
    <CardWithRadioHeader $isChecked={props.isSelected}>
      <div id="title" onClick={() => props.onSelect('AUTO')}>
        <RadioButton
          children={''}
          id={'auto-radio-title-button'}
          name={''}
          checked={props.isSelected}
        />
        Connexion à votre banque
      </div>
      <div id="container">
        <div>
          <span>Déclarez votre identité</span> puis connectez-vous à{' '}
          <span>votre banque.</span>
        </div>
        <DetailWrapper>
          <SVGIconWrapper>
            <base-icon icon="lock-close" />
          </SVGIconWrapper>
          <div>
            <span>Connexion sécurisée</span> et rapide à votre banque sur
            laquelle vous <span>percevez vos revenus.</span>
          </div>
        </DetailWrapper>

        <DetailWrapper>
          <SVGIconWrapper>
            <base-icon icon="contract-electronic" />
          </SVGIconWrapper>
          <div>
            Récupération <span>automatique</span> de votre <span>IBAN.</span>
          </div>
        </DetailWrapper>

        <DetailWrapper>
          <SVGIconWrapper>
            <base-icon icon="bank" />
          </SVGIconWrapper>
          <div>
            Récupération <span>automatique</span> de votre{' '}
            <span>historique</span> bancaire sur les 6 derniers mois.
          </div>
        </DetailWrapper>

        <div>
          <a slot="link" onClick={props.openModal}>
            {'Quelles sont les données récupérées ?'}
          </a>
        </div>
      </div>
    </CardWithRadioHeader>
  );
};

export default AutomaticAnalysysMethodCard;
