import { BackButton } from '@sweb-front/components';
import {
  BoldSpan,
  MainSectionWrapper,
  StyledParagraph,
  StyledTitle,
} from './Styles';
import { useEffect } from 'react';

const Cookies = () => {
  useEffect(() => {
    const cookies = document.getElementById('ot-sdk-cookie-policy');
    if (cookies) {
      cookies.style.display = 'initial';
    }
  }, []);

  return (
    <MainSectionWrapper>
      <BackButton />
      <StyledTitle>Politique cookies</StyledTitle>
      <StyledParagraph>
        Le Groupe BNP Paribas est particulièrement attentif à vous délivrer le
        meilleur service possible et nous souhaitons préserver la confiance que
        vous nous témoignez.
        <br />
        Dans ce cadre, nous avons adopté des principes forts afin d’assurer la
        protection de vos données.
      </StyledParagraph>
      <StyledParagraph>
        C&apos;est pourquoi nous souhaitons vous fournir des informations
        transparentes sur la manière dont nous plaçons, utilisons et stockons
        des cookies sur votre appareil lorsque vous consultez nos sites ou
        applications et vous présenter les options qui vous permettent de les
        gérer et les supprime.
      </StyledParagraph>
      <StyledTitle>Qu&apos;est-ce qu&apos;un cookie ?</StyledTitle>
      <StyledParagraph>
        Les cookies sont de petits fichiers texte, image ou logiciel qui peuvent
        être déposés et/ou lus sur votre appareil lorsque vous accédez à notre
        Site et/ou à l’Application. Le mot « appareil », lorsqu&apos;il est
        utilisé dans la présente Politique Cookies, désigne notamment les
        ordinateurs, les téléphones intelligents, les tablettes et tout autre
        appareil utilisé pour accéder à Internet.
        <br />
        Les cookies peuvent être (i) de session, ce qui signifie qu’ils sont
        supprimés de votre terminal une fois la session et le navigateur fermés
        ou (ii) persistants, ce qui signifie qu’ils seront stockés sur votre
        terminal jusqu’à leur suppression.
      </StyledParagraph>
      <StyledTitle>
        Quels types d’information peuvent être stockés dans un cookie ?
      </StyledTitle>
      <StyledParagraph>
        Les informations stockées par les cookies déposés sur votre appareil
        peuvent avoir trait aux éléments suivants, dans la limite de leur durée
        de conservation:
        <ul>
          <li>
            Les pages web que vous avez visitées en utilisant cet appareil
          </li>
          <li>Les publicités sur lesquelles vous avez cliqué</li>
          <li>Le type de navigateur que vous utilisez</li>
          <li>Votre adresse IP</li>
          <li>
            Et toute autre information que vous avez fournie sur notre
            Site/Application
          </li>
          Les cookies peuvent contenir des données à caractère personnel
          couvertes par notre{' '}
          <a href="https://broadcast.mediahub.bnpparibas/mh/external/master/c4314d4f-8e56-4d3a-8715-6292ea8e79ec">
            Notice de Protection des Données.
          </a>
        </ul>
      </StyledParagraph>
      <StyledTitle>
        Quel type de cookies utilisons nous ? Est-il possible de refuser
        l’installation de ces cookies ?
      </StyledTitle>
      <StyledParagraph>
        Les cookies que nous utilisons sur ce Site/cette Application sont
        exclusivement des cookies qui ont pour seul but de permettre ou
        faciliter la communication par voie électronique ou qui sont strictement
        nécessaires au fonctionnement du Site/de l’Application.{' '}
      </StyledParagraph>
      <StyledParagraph>
        Par exemple, les cookies strictement nécessaires que nous utilisons sur
        ce Site/cette Application peuvent notamment nous permettre de:
        <ul>
          <li>
            <BoldSpan>Vous authentifier et vous identifier</BoldSpan> sur notre
            Site et/ou l’Application afin de vous fournir les services que vous
            avez demandés
          </li>
          <li>
            <BoldSpan>
              Améliorer la sécurité du Site et/ou de l’Application
            </BoldSpan>{' '}
            y compris par la prévention de l’usage frauduleux d’identifiants de
            connexion et la protection des données utilisateurs de l’accès par
            des tiers non autorisés (par exemple en limitant les tentatives
            d’accès robotisé ou inattendues)
          </li>
          <li>
            <BoldSpan>
              Suivre votre manière d’utiliser notre Site et/ou Application afin
              de l’améliorer
            </BoldSpan>
            , par exemple en optimisant ses performances techniques ou son
            ergonomie
          </li>
          <li>
            <BoldSpan>Enregistrer vos préférences</BoldSpan> et vos paramètres
            d&apos;utilisation de notre Site Internet et/ou Application (par
            exemple langue, fuseau horaire, etc.)
          </li>
        </ul>
        L’utilisation des cookies strictement nécessaires au bon fonctionnement
        du Site/de l’Application ne requiert pas votre consentement. C’est
        pourquoi aucun module de gestion de vos préférences en matière de
        cookies n’est disponible sur ce Site/cette Application.
      </StyledParagraph>
    </MainSectionWrapper>
  );
};

export default Cookies;
