import { TAB } from '@sweb-front/utils';
import styled from 'styled-components';

export const PillContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  max-height: 2.8rem;
  max-width: 16rem;
  text-align: center;
  background-color: var(--blue-100);
  border-radius: 0.8rem;
  padding: 0.4rem 0.8rem;
  & sup {
    transform: translateY(-0.8rem);
    font-size: 1.2rem;
    font-family: 'Open Sans';
    letter-spacing: 0;
    line-height: 2rem;
  }
  @media screen and ${TAB} {
    padding: 0.6rem 0.8rem;
    column-gap: 0.35rem;
  }
`;
