import { TAB, DESKTOP } from '@sweb-front/utils';
import Button from '../components/Button/Button';
import styled from 'styled-components';

export const ButtonActionWrapper = styled.div`
  @media screen and ${TAB} {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 4rem 0;
    & .back-button-wrapper {
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
`;

export const StyledValidateButtonWrapper = styled.button<{
  $isDisabled?: boolean;
}>`
  color: var(--neutrals-0);
  border: none;
  border-radius: 4px;
  font-family: 'Open Sans Bold';
  font-size: 18px;
  line-height: 26px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  margin: 0;
  padding: 1rem 1.5rem 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 0;
  height: 5.6rem; /* 56px */
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ theme, $isDisabled }) =>
    $isDisabled ? theme.vendor.colors.disabled : theme.vendor.colors.primary};
  @media screen and ${TAB} {
    width: 11.6rem; /* 116px */
  }
`;

export const StyledModalValidateButtonWrapper = styled(Button)`
  cursor: pointer;
  color: var(--neutrals-0);
  border: none;
  border-radius: 4px;
  font-family: 'Open Sans Bold';
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 0.8rem 1.2rem;
  height: 3.6rem;
  width: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  margin: 0;
  padding: 1rem 1.5rem 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.vendor.colors.disabled : theme.vendor.colors.primary};
  @media screen and ${TAB} {
    width: 11.6rem; /* 116px */
  }
`;

export const StyledModalCancelButtonWrapper = styled(Button)`
  color: var(--neutrals-0);
  font-family: 'Open Sans Bold';
  height: 3.6rem;
  width: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  margin: 0;
  padding: 1rem 1.5rem 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 0;
  flex-grow: 0;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  background-color: #ffffff;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.vendor.colors.disabled : theme.vendor.colors.primary};
  @media screen and ${TAB} {
    width: 11.6rem; /* 116px */
  }
`;

export const StyledBackButtonWrapper = styled.div`
  height: 5.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const ModalLeaveWrapper = styled((props) => <ModalWrapper {...props} />)`
  @media screen and ${DESKTOP} {
    margin: 0 2.4rem;
    width: 100%;
  }
`;

export const CustomButtonActionWrapper = styled((props) => (
  <ButtonActionWrapper {...props} />
))`
  margin-bottom: 0rem;
  @media screen and ${TAB} {
    margin-bottom: 0.8rem;
    margin-top: 0;
  }
`;

export const CustomStyledBackButtonWrapper = styled((props) => (
  <StyledBackButtonWrapper {...props} />
))`
  margin-top: 1.6rem;
  @media screen and ${TAB} {
    margin-top: 0;
  }
`;
