import { APPPARAMS } from '@vat/configuration';

export const isStringEmpty = (value: string | null | undefined) => {
  return (
    !value || value === null || value === '' || (value ?? '').trim() === ''
  );
};

export const ascSort = (a: number, b: number) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

/**
 * {"ae123sdsds": {key: value, key1: value1, key2: value2}
 * @param token
 * @param storedKey
 * @returns
 */
export const getJSONDataFromStorage = (
  token: string,
  storedKey: string
): unknown => {
  if (
    isStringEmpty(token) ||
    isStringEmpty(storedKey) ||
    isStringEmpty(localStorage.getItem(storedKey))
  ) {
    return {};
  }

  const storedItems = JSON.parse(localStorage.getItem(storedKey) ?? '{}');
  if (
    !isStringEmpty(localStorage.getItem('token')) &&
    Object.keys(storedItems).length > 0
  ) {
    return storedItems[token];
  }
  return {};
};

export const getDataFromLocalStorage = (keyParam: string): unknown => {
  if (isStringEmpty(keyParam)) {
    return undefined;
  }

  const storedItems = JSON.parse(localStorage.getItem(keyParam) ?? '{}');
  if (Object.keys(storedItems).length > 0) {
    return storedItems;
  }
  return undefined;
};

export const isEmptySearchParams = (
  params: URLSearchParams,
  concernedKeys: string[]
): boolean => {
  const regexp = new RegExp('^redirectFrom');
  const valuesList = [] as string[];
  params.forEach((val, k) => {
    if (!regexp.test(val) && concernedKeys.includes(k)) {
      valuesList.push(val);
    }
  });
  return valuesList.every((val) => isStringEmpty(val));
};

export const isExternalApp = (url: string): boolean => {
  const regexp = new RegExp('^https://', 'i');
  return regexp.test(url);
};

export const isMainParamsEmptyForAnApp = (searchParams: URLSearchParams) => {
  if (searchParams.has('redirectFromMonext')) {
    return isEmptySearchParams(searchParams, APPPARAMS.redirectFromMonext);
  }
  if (searchParams.has('redirectFrom3DS')) {
    return isEmptySearchParams(searchParams, APPPARAMS.redirectFrom3DS);
  }
  if (searchParams.has('redirectFromSignature')) {
    return false;
  }
  return true;
};

export const isSearchParamsHasKey = (
  searchParams: URLSearchParams,
  redirection: string
) => {
  return searchParams.has(redirection);
};

export const isUrlContainsRedirectionParams = (
  searchParams: URLSearchParams
) => {
  return (
    searchParams.has('redirectFromMonext') ||
    searchParams.has('redirectFromSignature') ||
    searchParams.has('redirectFrom3DS') ||
    searchParams.has('redirectFromOpenBanking')
  );
};

export const isObjectEmpty = (obj: any): boolean => {
  return Object.keys(obj ?? {}).length < 1;
};

export const initRandomValue = () => {
  const buf = new Uint8Array(1);
  window.crypto.getRandomValues(buf);
  return buf[0];
};

export const concantenedObjectWithComma = (record: Record<string, string>) => {
  return Object.keys(record)
    .filter((key) => !isStringEmpty(record[key]))
    .map((key) => record[key])
    .join(', ');
};
// Escaping parentheses and apostrophes in keyword search for cities
export const escapeSearchKeyWordCity = (str: string) =>
  str.replace(/[()']/g, '\\$&');

export const replaceAccent = (inStr: string | undefined) => {
  return (inStr ?? '').replace(
    /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/gi,
    function (_, a, c, e, i, n, o, s, u, y, ae) {
      if (a) {
        return 'a';
      }
      if (c) {
        return 'c';
      }
      if (e) {
        return 'e';
      }
      if (i) {
        return 'i';
      }
      if (n) {
        return 'n';
      }
      if (o) {
        return 'o';
      }
      if (s) {
        return 's';
      }
      if (u) {
        return 'u';
      }
      if (y) {
        return 'y';
      }
      if (ae) {
        return 'ae';
      }
    }
  );
};

export const containAndStartByNumber = (el: string) => {
  return /^\d/.test(el);
};
